import React, { useState, useEffect , useRef} from "react";
import { Link } from "gatsby";
import Select, { components } from "react-select";
import { Container, Row, Col, Form } from "react-bootstrap";
import SelectOption from "./SelectOption/SelectOption";
import $ from "jquery";
import _ from "lodash"
import useWindowSize from "../common/useWindowSize";
import { navigate } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import {resiPropertyType, commPropertyTypeSale, commPropertyTypRent, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, SearchResultsURL, PropertyMinSize, PropertyMaxSize} from "../common/propertyUtils";
import SearchResultInputBox from './SearchResultBox';

import { useContainerContext } from "../../store/ContainerContext";

import "./Filter.scss";

const Filter = (props) => {

  const data = useStaticQuery(graphql`
    query CataglogueLink {  
      glstrapi {
        globalModule {
          Auction_Details {
            Current_Catalogue_Link
          }
        }
      }
    }
  `)
  var globalModule = data.glstrapi?.globalModule;
  

  const context = useContainerContext();
  const {search_list_department} = context.state;

  const [scroll, setScroll] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    try {
      window.addEventListener("scroll", () => {
        if( width < 768){
          setScroll(window.scrollY > 65)
        }
        else if( width >= 768 && width < 1200){          
          setScroll(window.scrollY > 30);
        } else if(width > 1200 && width < 1400){
          setScroll(window.scrollY > 70);
        } else{
          setScroll(window.scrollY > 120);
        }
      });
    } catch {}
  }, [width]);


  const [openFilter, setOpenFilter] = useState(false);
  const [isIndustrialPage, setIsIndustrialPage] = useState(false);
  const [areaVal, setAreaVal] = useState('');
  const [searchText, setSearchText] = useState('');

  const [propertyval, setPropertyVal] = useState('');
  const [salesVal, setSalesVal] = useState('');
  const [bedVal, setBedVal] = useState('');

  const [minpriceVal, setMinPriceVal] = useState('');
  const [maxpriceVal, setMaxPriceVal] = useState('');
  const [minPriceList, setMinPriceList] = useState([]);
  const [maxPriceList, setMaxPriceList] = useState([]);
  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);

  const [minsizeVal, setMinSizeVal] = useState('');
  const [maxsizeVal, setMaxSizeVal] = useState('');
  const [minSizeList, setMinSizeList] = useState([]);
  const [maxSizeList, setMaxSizeList] = useState([]);
  const [selectedMinSizeValue, setSelectedMinSizeValue] = useState(0);
  const [selectedMaxSizeValue, setSelectedMaxSizeValue] = useState(0);
  


  const filterOpen = () => {
    setOpenFilter(!openFilter);
  };

  // Min price
  const onChangeMinPrice = (e) => {
    setSelectedMinPriceValue(e.value);
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = (e) => {
    setSelectedMaxPriceValue(e.value);
  }
  // Max Price

  // Min Size
  const onChangeMinSize = (e) => {
    setSelectedMinSizeValue(e.value);
  }
  // Min price

  // Max Size
  const onChangeMaxSize = (e) => {
    setSelectedMaxSizeValue(e.value);
  }
  // Max Price

  // Sales price list
  var minPrice = [];
  var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

  if (minPrice.length > 0) {
  } else {
    minPrice.push(priceminRangeResiSale[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxPrice.push(pricemaxRangeResiSale[0])
  }
  // Sales price list

  // Size list
  var minSize = [];
  var maxSize = PropertyMaxSize.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinSizeValue)));

  PropertyMinSize.map(items => parseInt(items.value) < parseInt(selectedMaxSizeValue) && minSize.push(items));

  if (minSize.length > 0) {
  } else {
    minSize.push(PropertyMinSize[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxSize.push(PropertyMaxSize[0])
  }
  // Size list

  // Lettings price list
  var minPriceLettings = [];
  var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

  if (minPriceLettings.length > 0) {
  } else {
    minPriceLettings.push(priceminRangeResiRent[0])
  }

  if (maxPriceLettings.length > 0) {
  } else {
    maxPriceLettings.push(pricemaxRangeResiRent[0])
  }
  // Lettings price list

  // Price
  const priceButton = () => {
    $(".price_desk_select").toggleClass("active-filter");
  }

  const mobilePricebtn = () => {
    $(".price_mobile_select").toggleClass("active-filter");
  }
  // Price

  // Price
  const sizeButton = () => {
    $(".size_desk_select").toggleClass("active-filter");
  }

  const mobileSizebtn = () => {
    $(".size_mobile_select").toggleClass("active-filter");
  }
  // Price


  useEffect(() => {
    props.propertyType.trim() !== "property" ? setPropertyVal(props.propertyType) : setPropertyVal('');
  },[props.propertyType]);
  

  useEffect(() => {
    // Price dropdown outer click 
    $(document).mouseup(function(e) {
        var container = $(".price-dropdown-filter");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $(".price-dropdown-filter").removeClass("active-filter");
        }
    });
    // Price dropdown outer click

    // Price
    if (_.isEmpty(minPriceList)) {
      if (props.type === "lettings") {
        setMinPriceList(priceminRangeResiRent);
        setMaxPriceList(pricemaxRangeResiRent);
      } else {
        setMinPriceList(priceminRangeResiSale)
        setMaxPriceList(pricemaxRangeResiSale)
      }
    }
    // Price

    // Size
    if (_.isEmpty(minSizeList)) {
      setMinSizeList(PropertyMinSize);
      setMaxSizeList(PropertyMaxSize);
    }
    // Size
    //Submit scroll to top
    $(".search-btn").click(function() {
      $('html, body').animate({
          scrollTop: $(".search-property-header").offset().top
      }, 1000);
  })
//Submit scroll to top
  },[]);
  
  useEffect(()=>{
    setAreaVal(props.areas);
  },[props.areas])

  // Submit search
  const submitSearch = (e) => {
    //var areaValue = $(".location-search input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();

    var areaValue = $(".location-search .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();

    // var areaValue = "london"

    // var areaValue = $(".searchboxresults .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    // if(_.isEmpty(areaValue)){
    //   areaValue = $(".mobsearchboxresults .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    // }
    var searchTypeValue = salesVal;
    var bedroomValue = bedVal ? bedVal : $(".bedroom-input input[type='hidden']").val();
    var buildingValue = propertyval ? propertyval : $(".property input[type='hidden']").val();

    let priceUrl = ""
    if (maxpriceVal || minpriceVal) {
      if (minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
        priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
      } else
        if ((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
          priceUrl = "under-" + maxpriceVal
        }
        else if (minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
          priceUrl = "over-" + minpriceVal
        }
    }

    let sizeUrl = ""
    if (maxsizeVal || minsizeVal) {
      if (minsizeVal && maxsizeVal && minsizeVal != "0" && maxsizeVal != "0") {
        sizeUrl = "size-from-" + minsizeVal + "-to-" + maxsizeVal
      } else
        if ((minsizeVal == '' || minsizeVal == "0") && maxsizeVal && maxsizeVal != "0") {
          sizeUrl = "size-below-" + maxsizeVal
        }
        else if (minsizeVal && (maxsizeVal == '' || maxsizeVal == "0") && minsizeVal != "0") {
          sizeUrl = "size-above-" + minsizeVal
        }
    }

    var searchFilterResults = "";
    if(areaValue){
      if(props.type === "sales"){
        if (props.tag === "commercial") {
          searchFilterResults = "/commercial-property/for-sale/in-" + areaValue + "/";
        } else if (props.tag === "auctionSales") {
          searchFilterResults = "/auction-property/for-sale/in-" + areaValue + "/";
        } else if (props.department === "auction_commercial") {
          searchFilterResults = "/auction-commercial-property/for-sale/in-" + areaValue + "/";
        } else if (props.department === "auction_residential") {
          searchFilterResults = "/auction-residential-property/for-sale/in-" + areaValue + "/";
        } else {
          searchFilterResults = "/residential-property/for-sale/in-" + areaValue + "/";
        }
      } else {
        if (props.tag === "commercial") {
          searchFilterResults = "/commercial-property/to-rent/in-" + areaValue + "/";
        } else {
          searchFilterResults = "/residential-property/to-rent/in-" + areaValue + "/";
        }
      }
    } else{
      if(props.type === "sales"){
        if (props.tag === "commercial") {
          searchFilterResults = "/commercial-property/for-sale/in-london/";
        } else if (props.tag === "auctionSales") {
          searchFilterResults = "/auction-property/for-sale/in-uk/";
        } else if (props.department === "auction_commercial") {
          searchFilterResults = "/auction-commercial-property/for-sale/in-uk/";
        } else if (props.department === "auction_residential") {
          searchFilterResults = "/auction-residential-property/for-sale/in-uk/";
        } else {
          searchFilterResults = "/residential-property/for-sale/in-london/";
        }        
      } else {
        if (props.tag === "commercial") {
          searchFilterResults = "/commercial-property/to-rent/in-london/";
        } else {
          searchFilterResults = "/residential-property/to-rent/in-london/";
        }
      }
    }
    
    if(buildingValue && buildingValue !== "property"){
      searchFilterResults += "type-" + buildingValue + "/";
    }

    if(bedroomValue && bedroomValue != "0"){
      searchFilterResults += bedroomValue + "-and-more-bedrooms/";
    }

    if(bedroomValue && bedroomValue == 0){
      searchFilterResults += "studio/";
    }

    if(priceUrl){
      searchFilterResults += priceUrl +"/";
    }

    if(sizeUrl){
      searchFilterResults += sizeUrl +"/";
    }

    
    if(props.sortBy){
      searchFilterResults += 'sortby-'+props.sortBy;
    }

   setOpenFilter(false);
    navigate(searchFilterResults);
    
  }
  // Submit search

  // Page when load field in text boxes will same
  useEffect(() => {

    const url = typeof window !== 'undefined' ? window.location.href : ''
    let pathUri = url.split("/")
    for (let vi = 1; vi <= pathUri.length; vi++) {
      // check for area
      if (typeof pathUri[vi] === "undefined") {
          continue
      }

      // Property type
      if (pathUri[vi].indexOf("type-") >= 0) {
        setPropertyVal(pathUri[vi].replace("type-", ""))
      }
      // Property type

      // Area
      // if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-london") == -1)) {
      //   setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
      // }
      if(props.department === "commercial" || props.department === "residential"){
        if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-london") == -1)) {
          setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
        }
      } else{
        if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-uk") == -1)) {
          setAreaVal(_.upperFirst(pathUri[vi].replace("in-", "")))
        }
      }
      
      // Area
    
      // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
      ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            setMinPriceVal(priceFilt1[1])
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            setMaxPriceVal(priceFilt2[1])
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
          let priceFilt4 = priceFilt3[1].split("-and-")
            setMinPriceVal(priceFilt4[0])
            setMaxPriceVal(priceFilt4[1])
          }
      }
      // Price

      // Size
      if (pathUri[vi].indexOf("size-") >= 0) {
        let sizeFilt1 = pathUri[vi].split("above-")
        if (sizeFilt1[1]) {
          setMinSizeVal(sizeFilt1[1])
        }
        let sizeFilt2 = pathUri[vi].split("below-")
        if (sizeFilt2[1]) {
          setMaxSizeVal(sizeFilt2[1])
        }
        let sizeFilt3 = pathUri[vi].split("-from-")
        if (sizeFilt3[1]) {
        let sizeFilt4 = sizeFilt3[1].split("-to-")
          setMinSizeVal(sizeFilt4[0])
          setMaxSizeVal(sizeFilt4[1])
        }
      }
      // Size

      // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          setBedVal(pathUri[vi].replace("-and-more-bedrooms", ""))
      }
      // Bedrooms

      // is Industrial Page
        if (pathUri[vi].indexOf("type-industrial") >= 0) {
          setIsIndustrialPage(true);
      }
      // Bedrooms

      

    }
  }, []);
  // Page when load field in text boxes will same

  // Buy & Rent btn
  const buyBtn = () => {
    if (props.tag === "commercial") {
      if(isIndustrialPage){
        navigate(`/commercial-property/for-sale/in-uk/type-industrial/`)
      }else{
        navigate(`/commercial-property/for-sale/in-london/`)
      } 
    } else {
      navigate(`/residential-property/for-sale/in-london/`)
    }
  }

  const rentBtn = () => {
    if (props.tag === "commercial") {
      if(isIndustrialPage){
        navigate(`/commercial-property/to-rent/in-uk/type-industrial/`)
      }else{
        navigate(`/commercial-property/to-rent/in-london/`)
      } 
    } else {
      navigate(`/residential-property/to-rent/in-london/`)
    }    
  }

  const aucBtn = () => {
    navigate(`/auction-property/for-sale/in-uk/`)
  }

  const aucCommBtn = () => {
    navigate(`/auction-commercial-property/for-sale/in-uk/`)
  }

  const aucResiBtn = () => {
    navigate(`/auction-residential-property/for-sale/in-uk/`)
  }
  // Buy & Rent btn

  // console.log("propertyval", propertyval);

  var propertyListDropdown = resiPropertyType;
  if(props.department === "commercial"){
    propertyListDropdown = commPropertyTypeSale;

    if(props.type === "lettings"){
      propertyListDropdown = commPropertyTypRent;
    }
  }

  const handlePropertyChange = (value) => {
    setPropertyVal(value); 
    //props.handlePropType(value)
  }

  useEffect(()=>{
    props.searchListDepart(search_list_department);

  },[search_list_department])

  //console.log("propertyType", props.propertyType);

  return (
    <React.Fragment>
      <section className={ scroll ? "filter fixed-filter" : "filter"}>
        <form action="javascript:;" method="post" onSubmit={submitSearch}>
        <Container>
          <Row>
            <Col>
              <div className="filter-search d-lg-none">
                <div className="search-box">
                  <a href="javascript:void(0)" onClick={filterOpen}>
                    <i className="icon-filter" ></i>
                  </a>
                  <div className="h2" onClick={filterOpen}>filter your search</div>
                </div>
                <a href="javascript:void(0)" onClick={filterOpen}>
                  <i className="icon-right" ></i>
                </a>
              </div>
              <div
                className={
                  openFilter
                    ? "filter-field d-none d-lg-flex open"
                    : "filter-field d-none d-lg-flex"
                }
              >
                <div className={`btn-field ${props.department === "auction_commercial" || props.department === "auction_residential" ? "auc_filter_btn" : ""}`}>

                  <div className={props.tag === "auction" || props.tag === "auctionSales" ? "btn-wrapper auction-sales" : "btn-wrapper"}>
                  {
                    props.tag === "auction" || props.tag === "auctionSales" ? 
                    <>
                      <a href="javascript:void(0)" className={props.tag === "auctionSales" ? "btn" : (props?.department === "auction_commercial" ? "btn active" : "btn")} onClick={aucCommBtn}>Commercial</a>
                      <a href="javascript:void(0)" className={props.tag === "auctionSales" ? "btn" : (props?.department === "auction_residential" ? "btn active" : "btn")} onClick={aucResiBtn}>Residential</a>
                      <a href="javascript:void(0)" className={props.tag === "auctionSales" ? "btn active" : "btn"} onClick={aucBtn}>Both</a>
                    </> 
                    :
                    <>
                      <a href="javascript:void(0)" className={props?.type === "sales" ? "btn active" : "btn"} onClick={buyBtn}>Buy</a>
                      {props.propertyType !== "investment" && 
                      <a href="javascript:void(0)" className={props?.type === "lettings" ? "btn active" : "btn"} onClick={rentBtn}>Rent</a>
                      }
                      
                    </>
                  }
                  {/* {filterQuickLink.map((filter, i) => (
                    <strong
                      href={filter.href}
                      key={i}
                      onClick={(e) => handleClick(filter.label)}
                      className={`${filter.classname} ${filter.isActive ? "active" : ""
                        } `}
                    >
                      {filter.label}
                    </strong>
                  ))} */}
                  </div>

                  
                  <div className={`location-search ${props.department === "auction_commercial" || props.department === "auction_residential" ? "auction_input_box" :""}`}>
                    <SearchResultInputBox areaVal={areaVal} placeHolder={`Location, region or postcode`} department={props?.department} handleChange={setSearchText} />
                      {/* <Form.Group>
                        <Link to="#">
                          <i className="icon-map"></i>
                        </Link>
                        <Form.Control
                          type="search"
                          placeholder="Location, region or postcode"
                          handleChange={(val)=> setAreaVal(val)}
                          defaultValue={areaVal}
                        />
                      </Form.Group> */}
                  </div>
                </div>

                <div className={`btn-wrapper buttons ${props.department === "auction_commercial" || props.department === "auction_residential" ? "auc_filter_btn" : ""}`}>
                  <div className="select-box-section">
                    
                    {/* =========== Property Size ======== */}
                    {
                      props.department === "commercial" && <>
                          <div className="price-dropdown-wrapper size_dropdown d-lg-none d-block">
                            <button type="button" onClick={mobileSizebtn} className="category-select price-dropdown d-flex ">Size</button>
                            <div className="price-mobile-dropdown size_mobile_select">
                              <div className="category-select price price-dropdown-field">
                                <SelectOption 
                                  placeHolder={minsizeVal ? minsizeVal+ " sqft" : "MIN SIZE"}
                                  options={parseInt(selectedMaxSizeValue) === 0 ? minSizeList : minSize}
                                  DefaultValue={minSizeList.find(obj => obj.value === minsizeVal)}
                                  handleChange={e => { onChangeMinSize(e); setMinSizeVal(e.value) }}
                                />
                              </div>
                              <div className="category-select price price-dropdown-field">
                                <SelectOption
                                  placeHolder={maxsizeVal ? maxsizeVal+ " sqft" : "MAX SIZE"}
                                  options={parseInt(selectedMinSizeValue) === 0 ? maxSizeList : maxSize}
                                  DefaultValue={maxSizeList.find(obj => obj.value === maxsizeVal)}
                                  handleChange={e => { onChangeMaxSize(e); setMaxSizeVal(e.value) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="price-dropdown-wrapper size_dropdown d-lg-block d-none">
                            <button type="button" onClick={sizeButton} className="category-select price-dropdown d-flex align-items-center justify-content-center">Size</button>
                            <div className="price-dropdown-filter size_desk_select">
                              <div className="category-select price price-dropdown-field">
                                <SelectOption 
                                  placeHolder={minsizeVal ? minsizeVal+ " sqft" : "MIN SIZE"}
                                  options={parseInt(selectedMaxSizeValue) === 0 ? minSizeList : minSize}
                                  DefaultValue={minSizeList.find(obj => obj.value === minsizeVal)}
                                  handleChange={e => { onChangeMinSize(e); setMinSizeVal(e.value) }}
                                />
                              </div>
                              <div className="category-select price price-dropdown-field">
                                <SelectOption
                                  placeHolder={maxsizeVal ? maxsizeVal+ " sqft" : "MAX SIZE"}
                                  options={parseInt(selectedMinSizeValue) === 0 ? maxSizeList : maxSize}
                                  DefaultValue={maxSizeList.find(obj => obj.value === maxsizeVal)}
                                  handleChange={e => { onChangeMaxSize(e); setMaxSizeVal(e.value) }}
                                />
                              </div>
                            </div>
                          </div>
                      </> 
                    }
                    {/* =========== Property Size ======== */}
                    
                    {/* =========== Property Price ======== */}
                    <div className="price-dropdown-wrapper d-lg-none d-block">
                      <button type="button" onClick={mobilePricebtn} className="category-select price-dropdown d-flex ">Price</button>
                      <div className="price-mobile-dropdown price_mobile_select">
                        <div className="category-select price price-dropdown-field">
                          <SelectOption 
                            placeHolder={minpriceVal ? (props.type === "sales" ? "£"+minpriceVal : "£"+minpriceVal+ "PCM") : "MIN PRICE"}
                            options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.type === "sales" ? minPrice : minPriceLettings)}
                            DefaultValue={minPriceList.find(obj => obj.value === minpriceVal)}
                            handleChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                          />
                        </div>
                        <div className="category-select price price-dropdown-field">
                          <SelectOption
                            placeHolder={maxpriceVal ? (props.type === "sales" ? "£"+maxpriceVal : "£"+maxpriceVal+ "PCM") : "MAX PRICE"}
                            options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.type === "sales" ? maxPrice : maxPriceLettings)}
                            DefaultValue={maxPriceList.find(obj => obj.value === maxpriceVal)}
                            handleChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="price-dropdown-wrapper d-lg-block d-none">
                      <button type="button" onClick={priceButton} className="category-select price-dropdown d-flex align-items-center justify-content-center">Price</button>
                      <div className="price-dropdown-filter price_desk_select">
                        <div className="category-select price price-dropdown-field">
                          <SelectOption 
                            placeHolder={minpriceVal ? (props.type === "sales" ? "£"+minpriceVal : "£"+minpriceVal+ "PCM") : "MIN PRICE"}
                            options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.type === "sales" ? minPrice : minPriceLettings)}
                            DefaultValue={minPriceList.find(obj => obj.value === minpriceVal)}
                            handleChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                          />
                        </div>
                        <div className="category-select price price-dropdown-field">
                          <SelectOption
                            placeHolder={maxpriceVal ? (props.type === "sales" ? "£"+maxpriceVal : "£"+maxpriceVal+ "PCM") : "MAX PRICE"}
                            options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.type === "sales" ? maxPrice : maxPriceLettings)}
                            DefaultValue={maxPriceList.find(obj => obj.value === maxpriceVal)}
                            handleChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* =========== Property Price ======== */}

                    {/* =========== Property Types ======== */}
                    {props.tag === "auction" || props.tag === "auctionSales" ? ""
                      :
                      <div className="category-select property">
                        <SelectOption
                          options={propertyListDropdown}
                          value={propertyListDropdown.find(obj => obj.value === propertyval)}
                          placeHolder={propertyval ? propertyval : "property type"}
                          handleChange={e => setPropertyVal(e.value)}
                        />
                      </div>
                    }


                    {/* =========== Bedrooms ======== */}
                    {props.tag === "commercial" || props.tag === "auction" || props.tag === "auctionSales" ? "" 
                      :
                      <div className="category-select bedrooms">
                        <SelectOption
                          options={bedroomsRange}
                          value={bedroomsRange.find(obj => obj.value === bedVal)}
                          placeHolder={bedVal ? bedVal+" +" : "Bedrooms"}
                          // DefaultValue={{ value: "", label: "Any" }}
                          handleChange={e => setBedVal(e.value)}
                        />
                      </div> 
                    }
                    {/* <div className="category-select category tenure">
                      <SelectOption placeHolder="tenure" options={tenure} />
                    </div> */}
                    <button className="btn btn-primary black_btn search-btn me-0" type="submit">Search</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        </form>
        
      </section>
    </React.Fragment>
  );
};
export default Filter;
