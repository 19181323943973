import React, { useState, useEffect, PropTypes, useRef } from "react"
import $ from "jquery";
import qs from "qs"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import algoliasearch from "algoliasearch/lite"
import Modal from "react-bootstrap/Modal"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    ToggleRefinement,
    connectSortBy,
    connectAutoComplete,
    ClearRefinements,
    connectToggleRefinement
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { Helmet } from "react-helmet"
import ScrollAnimation from "react-animate-on-scroll";
import Select, { components } from "react-select";
import { isTablet, isIPad13, isIOS13, isMobile,isMobileOnly, isDesktop, browserName } from 'react-device-detect';
import Filter from "../components/SearchListing/Filter";
import SeoResults, { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "../components/property-search/seoResults"
import { parseSearchUrl, propertyH1, savedSearchParams, propertyH1Intro, toTitleCase } from "../components/property-search/utils";
import Sales from "../components/SearchListing/Sales/Sales";
import PropertyInfo from "../components/SearchListing/PropertyInfo/PropertyInfo";
import PropertyList from "../components/SearchListing/PropertyInfo/PropertyList";
import NoResultList from '../components/SearchListing/PropertyInfo/NoResultList';
import MarketAds from '../components/SearchListing/PropertyInfo/MarketAds';

import Mob01 from "../images/searchlist/mob-img01.png";
import Desk01 from "../images/searchlist/desk01.png";
import Contact01 from "../images/searchlist/contact-img01.png";
import Map from "../images/searchlist/map.png";
// import SelectOption from "../components/SearchListing/SelectOption/SelectOption";
import {resiPropertyType, commPropertyTypeSale, commPropertyTypRent, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, SearchResultsURL, PropertyMinSize, PropertyMaxSize, SortByValues, SortByCommercial, SortByAuction} from "../components/common/propertyUtils";

import {CustomLinks, DynamicFormID,getOffset} from "../components/common/utils";

import Loadable from "@loadable/component"
import Layout from "../components/layout";

//import CreateEmailAlert from "../components/forms/create_email_alert"
import CreateEmailAlert from "../components/forms/register-your-search"
import CreateEmailAlertCommercial from "../components/forms/register-your-search-commercial"
import MemberForm from "../components/forms/member-form"
import HTMLReactParser from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby"
import BannerBottom from "../components/SearchListing/BannerBottom"
import { replace } from "lodash";
import axios from "axios";
import useDeviceMedia from "../hooks/useDeviceMedia";

const MapResultsDesktop = Loadable(() => import("../components/map/search-results-desktop-map"));
const MapResultsMobile = Loadable(() => import("../components/map/search-results-mobile-map"));

//
const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

var minsizeval = minsizeval || ""
var maxsizeval = maxsizeval || ""

var includeSold = false;
var isFitted = false;

const searchClient = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APP_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_API_KEY}`
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
//


// Property results loop
const InfiniteHits = ({hits,hasPrevious,refinePrevious,hasMore,refineNext,location,tag,auctionTag,userObjects,OpenMember, srch_depart, includeSold}) => {

  const { isTabletOnly,isMobile, isLargeScreen } = useDeviceMedia();
  useEffect(()=>{ 
    const handleScroll = () => {
      var headerHeight = document.getElementsByClassName("header")[0];
      document.getElementsByClassName("filter")[0].style.top  = headerHeight.offsetHeight+"px";
    }
    handleResize();
    window.addEventListener('resize', handleResize); 
    window.addEventListener("scroll", handleScroll);

function handleResize() {
  setTimeout(() => {
  var headerHeight = document.getElementsByClassName("header")[0];
  document.getElementsByClassName("filter")[0].style.top  = headerHeight.offsetHeight+"px";
 
  if(  0<hits.length && 768<=window.innerWidth ) {    
      const collection = document.getElementsByClassName("equalHeights");
      const commercial_title = document.getElementsByClassName("commercial_title");
      const officeinfo = document.getElementsByClassName("office-info");

      var maxHeight = 0;
      var commercialTitleHeight = 0;
      var officeinfoHeight = 0;
      for (var i=0; i < collection.length; i++) {
          if(collection[i].getAttribute("style")) {
              collection[i].style.removeProperty("height");
          }
          maxHeight = Math.max(maxHeight, collection[i].offsetHeight);
          if(0<commercial_title.length) {
              if(commercial_title[i].getAttribute("style")) {
                  commercial_title[i].style.removeProperty("height");
              }
              commercialTitleHeight = Math.max(commercialTitleHeight, commercial_title[i].offsetHeight);
          }
          if(0<officeinfo.length) {
            if(officeinfo[i].getAttribute("style")) {
              officeinfo[i].style.removeProperty("height");
            }
            officeinfoHeight = Math.max(officeinfoHeight, officeinfo[i].offsetHeight);
        }
      }
      for (var i=0; i < collection.length; i++) {
         collection[i].style.height = maxHeight+'px';
         if(0<commercial_title.length) {
          commercial_title[i].style.height = commercialTitleHeight+'px';
         }
         if(0<officeinfo.length) {
          officeinfo[i].style.height = officeinfoHeight+'px';
         }
      }
  }
}, 150);

}

},[hits,hasPrevious,refinePrevious,hasMore]);

    const loadMoreRef = useRef();

    console.log("hits", hits.length );

    //console.log("includeSold", includeSold)

    var hits_filter = hits;

    if(srch_depart === "investment" && includeSold === true){
      hits_filter = hits.filter(hit => hit?.status?.toLowerCase() !== "sold");
    }

    return(
    <>
        {(hits_filter.length > 0 && hasPrevious) && (
            <button
            id="myprevbut"
            onClick={refinePrevious}
            disabled={!hasPrevious}
            className="d-none property_show_more_btn"
            >
                Show prev
            </button>
        )}

        {
           hits_filter.map((hit, i) => {

                // property details url structure
                let uriStr = "";
                let mysale = "";

                if (hit.search_type === "sales" && hit.department === 'residential') {
                  uriStr = `residential-property-for-sale/`
                  mysale = `myResSaleProp`
                } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                  uriStr = `residential-property-to-rent/`
                  mysale = `myResRentProp`
                } else if (hit.search_type === "sales" && hit.department === 'commercial') {
                  uriStr = `commercial-property-for-sale/`
                  mysale = `myCommSaleProp`
                } else if (hit.search_type === "lettings" && hit.department === 'commercial') {
                  uriStr = `commercial-property-to-rent/`
                  mysale = `myCommRentProp`
                } else if(auctionTag === "auctionSales") {
                  uriStr = `auction-property-for-sale/`
                  mysale = `myAucSaleProp`
                } else if (hit.search_type === "sales" && hit.department === 'auction_residential') {
                  uriStr = `auction-residential-property-for-sale/`
                  mysale = `myAucResSaleProp`

                } else if (hit.search_type === "sales" && hit.department === 'auction_commercial') {
                  uriStr = `auction-commercial-property-for-sale/`
                  mysale = `myAucCommSaleProp`
                }
                // property details url structure

                var depart_cls = hit.department;
                if(hit.department === "auction_residential" || hit.department === 'auction_commercial'){
                  depart_cls = "auction";
                }



                return(
                    <>
                        <PropertyList
                            //className={`${srch_depart} ${hit?.status?.toLowerCase() === "sold" && includeSold === true ? "hide_prop" : ""}`}
                            className={`${srch_depart}`}
                            ObjectIDprop={hit.objectID}
                            // GeoLat={hit._geoloc.lat}
                            // GeoLng={hit._geoloc.lng}
                            MobImg={hit.images || []}
                            DeskImg={hit.images || []}
                            PropertyPrice={hit.price}
                            Title={hit.title}
                            Address={hit.display_address}
                            address_json={hit.address}
                            postcode={hit.postcode}
                            Sub_Area={hit?.subarea ? hit?.subarea : ""}
                            Offer={hit.status}
                            NegoName={hit.crm_negotiator_id}
                            No={hit.crm_negotiator_id}
                            Count={hit.images}
                            ContactImg={Contact01}
                            searchType={hit.search_type}
                            propertyDetailsLink={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                            OpenMember={OpenMember}
                            Department={hit.department}
                            extra={hit?.extra}
                            introtext={hit?.introtext}
                            building={hit.building}
                            status={hit.status}
                            depart_cls={depart_cls}
                            floorarea_min={hit.floorarea_min}
                            floorarea_max={hit.floorarea_max}
                            floorarea_type={hit.floorarea_type}
                            lotNumber={hit?.extra?.lotNumber}
                            availablelotStatus={hit?.availablelot_status}
                            rentPriceMetric={hit?.rentPriceMetric}
                            auctionDate={hit?.auctionDate}
                            auctionId={hit?.auctionId}
                            images1={hit?.images1 || []}
                        />
                       {
                          ((i + 1) % (isLargeScreen ?9 : 6)) === 0 ?
                            <MarketAds hit={hit}/>
                          : ''
                        }
                    </>
                )
            })
        }

        {(hits.length > 0 && hasMore) && (
            <div className="w-100 mt-5  me-lg-5 pe-xl-3 hide_load_mre" ref={loadMoreRef}>
                <button
                    id="mynextbut"
                    onClick={refineNext}
                    disabled={!hasMore}
                    className="btn btn-primary"
                >
                    Load More
                </button>
            </div>
        )}
    </>
    )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
        <div className="filter-search-input-box">
            <div className="dropdown">
                <i className="icon-location-input"></i>
                <Form.Control type="search" placeholder="Street, area or postcode" id="formBasicEmail"
                  onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
                  value={currentRefinement.replace(/-/g, ' ')}
                />
            </div>
        </div>
    )
})
// Searchbox

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine, department, ptype }) => {
    var propertyListDropdown = resiPropertyType;
    if (department === "commercial") {
      propertyListDropdown = commPropertyTypeSale;
      if(ptype === "lettings"){
        propertyListDropdown = commPropertyTypRent;
      } else{
        propertyListDropdown = commPropertyTypeSale;
      }

    }

    return (
      <Select
        name="building"
        options={propertyListDropdown}
        // value={{ value: 0, label: "All" }}
        placeholder="Property Type"
        onChange={(e) => { refine(e.value, e.label) }}
        isSearchable={false}
      />
    )
}

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Bedrooms
const bedroomSelectBox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
  }) => {

    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
      // bedroomvalselect = 0
      bedroomvalselect = currentRefinement.max
    } else if (currentRefinement.min !== undefined) {
      bedroomvalselect = currentRefinement.min
    }

    return (
      <Select
        name="bedroom"
        options={bedroomsRange}
        // value={{ value: "", label: "Any" }}
        placeholder="Bedrooms"
        onChange={(e) => {
          if (e.value === "") {
            refine(e.value)
          } else if (e.value === "0") {
            refine({
              max: e.value,
            })
          } else {
            refine({
              min: e.value,
            })
          }
        }}
        isSearchable={false}
      />
    )
}

const CustombedroomSelectbox = connectRange(bedroomSelectBox)
// Property Bedrooms

// Min Price
const minpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
    type
}) => {
    var minPriceRange = priceminRangeResiSale;
    if (type === "lettings") {
      minPriceRange = priceminRangeResiRent;
    }
    if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
      minPriceRange = minPriceRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }

    minpriceval = currentRefinement.min
    return (
      <Select
        name="minprice"
        options={minPriceRange}
        isSearchable={false}
        placeholder="Min Price"
        onChange={(e) => {
          minpriceval = e.value
          if (e.value === "0") {
            if (maxpriceval !== "0") {
              refine({
                max: maxpriceval,
              })
            } else refine(e.value, e.label)
          } else {
            refine({
              min: e.value,
              max: maxpriceval,
            })
          }
        }}
      />
    )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Min Price

// Max Price
const maxpriceSelectbox = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
    type
}) => {
    var maxPriceRange = pricemaxRangeResiSale;
    if (type === "lettings") {
      maxPriceRange = pricemaxRangeResiRent;
    }
    let maxpricedisable = ''
    if (currentRefinement.min !== undefined) {
      maxPriceRange = maxPriceRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "400000") {
      maxpricedisable = 'disabled'
    }
    maxpriceval = currentRefinement.max
    return (
      <Select
        name="maxprice"
        options={maxPriceRange}
        defaultValue={{ value: "0", label: "Max Price" }}
        placeholder="Max Price"
        classNamePrefix={"select-opt"}
        isSearchable={false}
        onChange={(e) => {
          maxpriceval = e.value
          if (e.value === "0") {
            if (minpriceval) {
              refine({
                min: minpriceval,
              })
            } else refine(e.value, e.label)
          } else {
            refine({
              min: minpriceval,
              max: e.value,
            })
          }
        }}
      />
    )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Max Price

// Min Size
const minsizeSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type
}) => {

  var minSizeRange = PropertyMinSize;
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minSizeRange = minSizeRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  minsizeval = currentRefinement.min
  return (
    <Select
      name="minsize"
      options={minSizeRange}
      isSearchable={false}
      placeholder="Min Size"
      onChange={(e) => {
        minsizeval = e.value
        if (e.value === "0") {
          if (maxsizeval !== "0") {
            refine({
              max: maxsizeval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: e.value,
            max: maxsizeval,
          })
        }
      }}
    />
  )
}

const CustomminsizeSelectbox = connectRange(minsizeSelectbox)
// Min Size


// Max Size
const maxsizeSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type
}) => {
  var maxSizeRange = PropertyMaxSize;

  let maxpricedisable = ''
  if (currentRefinement.min !== undefined) {
    maxSizeRange = maxSizeRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
  }
  if (currentRefinement.min == "400000") {
    maxpricedisable = 'disabled'
  }
  maxsizeval = currentRefinement.max
  return (
    <Select
      name="maxsize"
      options={maxSizeRange}
      defaultValue={{ value: "0", label: "Max Size" }}
      placeholder="Max Size"
      classNamePrefix={"select-opt"}
      isSearchable={false}
      onChange={(e) => {
        maxsizeval = e.value
        if (e.value === "0") {
          if (minsizeval) {
            refine({
              min: minsizeval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: minsizeval,
            max: e.value,
          })
        }
      }}
    />
  )
}

const CustommaxsizeSelectbox = connectRange(maxsizeSelectbox)
// Max Size

// Sort by
const CustomSortBy = ({ items, refine, currentRefinement, placeholder }) => (
    <div className="prices select-option sort_option">
      <Select
        isSearchable={false}
        placeholder={placeholder}
        defaultValue={currentRefinement || ''}
        onChange={(e) => { refine(e.value, e.label) }}
        options={items}
        classNamePrefix={"select-opt"}
      />
    </div>
);

const CustomSortByFilter = connectSortBy(CustomSortBy);
// Sort by

// Include sold and let
const ToggletttRefinement = ({
    currentRefinement,
    label,
    count,
    refine,
    createURL,
  }) => {

    //console.log("currentRefinement ==>", currentRefinement)

    var include_sold = currentRefinement;
    return (
      <a
        href={'javascripti:;'}
        onClick={event => {
          event.preventDefault();
          includeSold = !includeSold;
          refine(!currentRefinement);
        }}
      >
        {/* <div className="form-check include-sold-let">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={includeSold ? "checked" : ""} />
            <label className="form-check-label" for="flexCheckDefault">{label}</label>
        </div> */}

        <label className={`property-checkbox-wrapper ${include_sold}`}>{label}
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={include_sold ? "checked" : ""} />
          <span className="input-checkmark"></span>
        </label>

        {/* <div className="switch-button ">
          <label for="toggle-button">
            <input type="checkbox" id="toggle-button" checked={includeSold ? "checked" : ""} />
            <span className="toggle-switch">
              <span className="toggle-switch-bar"></span>
            </span>
            <span className="toggle-text">{label}</span>
          </label>
        </div> */}
      </a>
    )
}
const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement);
// Include sold and let

// IsFitted Toggle
const FittedToggletttRefinement = ({
  currentRefinement,
  label,
  count,
  refine,
  createURL,
  property_type
}) => {

  //console.log("property_type ==>", property_type);
  return (
    <a
      href={'javascripti:;'}
      onClick={event => {
        event.preventDefault();
        isFitted = !isFitted;
        refine(!currentRefinement);
      }}
      className={`fitted_toggle ${property_type}`}
    >
      <label className="property-checkbox-wrapper">{label}
        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2" checked={isFitted ? "checked" : ""} />
        <span className="input-checkmark"></span>
      </label>
    </a>
  )
}
const CustomFitToggleRefinement = connectToggleRefinement(FittedToggletttRefinement);
// IsFitted Toggle

// No Results
const NoStats = ({ processingTimeMS, nbHits, location, status, department, department2, OpenMember}) => {

    //console.log("nbHits", nbHits);
    return (
      <>
        {nbHits === 0 &&
          <>
            <Row className="no_res_found">
              <Col lg={12} className="no-properties-text mb-0">
                <h4 className="mb-0">Unfortunately, we do not currently have any properties that match your search criteria.</h4>
              </Col>
            </Row>
            <NoResultList uriStr={location} status={status} department={department} department2={department2} OpenMember={OpenMember}/>
          </>
        }
      </>
    )
  }

const CustomNoStats = connectStats(NoStats)
// No Results

// Notice bidder
const NoticeBidder = () => {
  const data = useStaticQuery(graphql`
    query Notice_Bidder {
      glstrapi {
        globalModule {
          Auction_Details {
            Notice_Bidder_Content
          }
        }
      }
    }
  `)

  var globalModule = data?.glstrapi?.globalModule;

  return (
    globalModule?.Auction_Details?.Notice_Bidder_Content &&
    <div className="notice_bidder">{HTMLReactParser(globalModule?.Auction_Details?.Notice_Bidder_Content)} </div>
  )
}
// Notice bidder


// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Mobile Map
const MapResultsDevice = (() => {
  return (
     <>
      <div id="map" className="map">
        <GoogleMapsLoader apiKey={process.env.GATSBY_MAPS_API_KEY}>
        {/* <GoogleMapsLoader apiKey={""}> */}
          {google => <MapResults1 google={google} />}
        </GoogleMapsLoader>
      </div>
    </>
  )
})
var InfoWindow;
var onClickMarker;
var html;

const MapResults1 = ((props) => {
  InfoWindow = new props.google.maps.InfoWindow();

  onClickMarker = ({ hit, marker }) => {

    if (InfoWindow.getMap()) {
      InfoWindow.close();
    }


    // property details url structure
    let uriStr = "";

    if (hit.search_type === "sales" && hit.department === 'residential') {
      uriStr = `residential-property-for-sale/`
    } else if (hit.search_type === "lettings" && hit.department === 'residential') {
      uriStr = `residential-property-to-rent/`
    } else if (hit.search_type === "sales" && hit.department === 'commercial') {
      uriStr = `commercial-property-for-sale/`
    } else if (hit.search_type === "lettings" && hit.department === 'commercial') {
      uriStr = `commercial-property-to-rent/`
    }
    // else if(auctionTag === "auctionSales") {
    //   uriStr = `auction-property-for-sale/`
    // }
    else if (hit.search_type === "sales" && hit.department === 'auction_residential') {
      uriStr = `auction-residential-property-for-sale/`
    } else if (hit.search_type === "sales" && hit.department === 'auction_commercial') {
      uriStr = `auction-commercial-property-for-sale/`
    }
    // property details url structure

    // available days
    let currFullDate = new Date();
    let currYear = currFullDate.getFullYear();
    let currMonth = currFullDate.getMonth() +1;
    let currDate = currFullDate.getDate();
    let currDateCal = currYear+"-"+currMonth+"-"+currDate;
    let propertyDate = hit.AvailableFrom;
    let availableFrom = "";
    let date1 = new Date(propertyDate).getTime();
    let date2 = new Date(currDateCal).getTime();

    if(date1 > date2) {
      var timeDiff = Math.abs(date1 -date2);
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      availableFrom = diffDays;
    } else { }
    // available days

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined ) {
      propertyAddressFinal = hit.display_address;
    } else {
      var propertyAddress = hit.display_address;
      var propertySplit = propertyAddress.split(',');

      for(var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i].replace(/^\s*/, "").replace(/\s*$/, "");
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0];
      }
    }

    html = '<a href="/'+uriStr+hit.slug+'-'+hit.objectID+'"><div class="map-property-card"><div class="slider"><img style="width:100%; height:auto;" src="'+hit.images[0]["311x220"]+'" alt="'+hit.display_address+'" /></div><div class="content"><h5>'+hit.display_address+'</h5><p>'+hit.title+'</p><p class="price">£'+hit.price.toLocaleString()+'</p></div></div></a>';

    InfoWindow.setContent(html);

    InfoWindow.open(marker.getMap(), marker);
  };
  return (
     <>
        <GeoSearch google={props.google} >
            {({ hits }) => (
                <div>
                    {hits.map(hit => (
                        <>
                            <CustomMarker
                                anchor={{ x: 0, y: -5 }}
                                key={hit.objectID}
                                position={hit._geoloc}
                                hit={hit}
                                className={"icon-property_map_marker map-marker-"+ hit.objectID}
                                onClick={({ marker }) => {
                                    onClickMarker({ hit, marker, });
                                }}
                            />
                        </>
                    ))}
                </div>
            )}
        </GeoSearch>
    </>
  )
})
// Mobile Map

// Create URL
export const createURL = (props, state, location) => {

    let myarea = state.menu

    const isDefaultRoute =
      !state.query &&
      !state.SortBy &&
      state.page === 1 &&
      state.menu &&
      state.range &&
      !state.range.price &&
      !state.range.floorarea_min_sort &&
      !state.range.bedroom

    if (isDefaultRoute) {
      return ""
    }

    //var areaPath = "in-london/"
    var areaPath = "in-uk/"
    var bedroomPath = ""
    var pricePath = ""
    var pagePath = ""
    var propertypath = ""
    var sortPath = ""
    var sizePath = ""

    if (state.query) {
      if (state.query) {
        areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
      }
    }

    const queryParameters = {}

    // Property path
    if (state.menu) {
      if (myarea["building"]) {
        propertypath = "type-" + myarea["building"] + "/"
      }
    }



    // Property path

    // Bedrooms & Price range path
    if (state.range) {
      // Bedrooms
      if (state.range.hasOwnProperty('bedroom') && parseInt(state.range.bedroom.max) == 0) {
        bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
      }
      if (state.range.bedroom) {
        if (state.range.bedroom.min) {
          bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
        }
      }

      // Price
      if (state.range.price) {
        if (state.range.price.min && state.range.price.max) {
          pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
        } else if (state.range.price.max) {
          pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
        } else if (state.range.price.min) {
          pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
        }
      }

      // Size
      if (state.range.floorarea_min_sort) {
        if (state.range.floorarea_min_sort.min && state.range.floorarea_min_sort.max) {
          sizePath = "size-from-" + state.range.floorarea_min_sort.min + "-to-" + state.range.floorarea_min_sort.max + '/'
        } else if (state.range.floorarea_min_sort.max) {
          sizePath = "size-below-" + state.range.floorarea_min_sort.max + '/'
        } else if (state.range.floorarea_min_sort.min) {
          sizePath = "size-above-" + state.range.floorarea_min_sort.min + '/'
        }
      }
    }
    // Bedrooms & Price range path

    // Sort path
    if (state.sortBy) {
      if (state.sortBy === index_name + "_price_asc") {
        sortPath = "sortby-price-asc/";
      }
      if (state.sortBy === index_name + "_price_desc") {
        sortPath = "sortby-price-desc/";
      }
      if (state.sortBy === index_name + "_size_desc") {
        sortPath = "sortby-size-desc/";
      }
      if (state.sortBy === index_name + "_size_asc") {
        sortPath = "sortby-size-asc/";
      }
    //   if (state.sortBy === index_name + "_recent") {
    //     sortPath = "sortby-most-recent/";
    //   }
    }
    // Sort path


    // Page path
    if (state.page && state.page !== 1) {
      pagePath = `page-${state.page}/`
    }
    // Page path

    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    })


    let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${sizePath}${queryString}${pagePath}${sortPath}`

    if ("/" === myUrl.substr(myUrl.length - 1))
      myUrl = myUrl.substr(0, myUrl.length - 1)

    return `${location}${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, location) =>
    searchState ? `${createURL(props, searchState, location)}` : ""

const urlToSearchState = location => {
    if (location) {
      var query = ""
      let pathUri_main = ''

      let a1 = location.pathname.indexOf("/residential-property/for-sale") > -1
      let a2 = location.pathname.indexOf("/residential-property/to-rent") > -1
      let a3 = location.pathname.indexOf("/commercial-property/for-sale") > -1
      let a4 = location.pathname.indexOf("/commercial-property/to-rent") > -1
      let a5 = location.pathname.indexOf("/auction-property/for-sale") > -1
      let a6 = location.pathname.indexOf("/auction-residential-property/for-sale") > -1
      let a7 = location.pathname.indexOf("/auction-commercial-property/for-sale") > -1

      if (a1 == true) {
        pathUri_main = location.pathname.split("/residential-property/for-sale")
      } else if (a2 == true) {
        pathUri_main = location.pathname.split("/residential-property/to-rent")
      } else if (a3 == true) {
        pathUri_main = location.pathname.split("/commercial-property/for-sale")
      } else if (a4 == true) {
        pathUri_main = location.pathname.split("/commercial-property/to-rent")
      } else if (a5 == true) {
        pathUri_main = location.pathname.split("/auction-property/for-sale")
      } else if (a6 == true) {
        pathUri_main = location.pathname.split("/auction-residential-property/for-sale")
      } else if (a7 == true) {
        pathUri_main = location.pathname.split("/auction-commercial-property/for-sale")
      } else {
        pathUri_main = location.pathname.split(location)
      }

      var areaVal = ""
      var bedVal = 10
      var minpriceVal = ""
      var maxpriceVal = ""
      var pageVal = ""
      var propertyval = ""
      var sortVal = ""
      var sortVal_filt = ""
      var minsizeVal = ""
      var maxsizeVal = ""

      if (pathUri_main[1]) {
        // following could be regexp
        let pathUri = pathUri_main[1].split("/")

        //lets loop
        for (let vi = 1; vi <= pathUri.length; vi++) {
          // check for area
          if (typeof pathUri[vi] === "undefined") {
            continue
          }

          // Property type
          if (pathUri[vi].indexOf("type-") >= 0) {
            propertyval = pathUri[vi].replace("type-", "")
          }

          // Area
          if (pathUri[vi].indexOf("in-") >= 0) {
            areaVal = pathUri[vi].replace("in-", "")
          }

          // Price
          if (
            pathUri[vi].indexOf("between-") >= 0 ||
            pathUri[vi].indexOf("over-") >= 0 ||
            pathUri[vi].indexOf("under-") >= 0
          ) {
            let priceFilt1 = pathUri[vi].split("over-")
            if (priceFilt1[1]) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split("under-")
            if (priceFilt2[1]) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split("between-")
            if (priceFilt3[1]) {
              let priceFilt4 = priceFilt3[1].split("-and-")
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }


          // Size
          if (pathUri[vi].indexOf("size-") >= 0) {
            let sizeFilt1 = pathUri[vi].split("above-")
            if (sizeFilt1[1]) {
              minsizeVal = sizeFilt1[1]
            }
            let sizeFilt2 = pathUri[vi].split("below-")
            if (sizeFilt2[1]) {
              maxsizeVal = sizeFilt2[1]
            }
            let sizeFilt3 = pathUri[vi].split("-from-")
            if (sizeFilt3[1]) {
            let sizeFilt4 = sizeFilt3[1].split("-to-")
              minsizeVal = sizeFilt4[0]
              maxsizeVal = sizeFilt4[1]
            }
          }
          // Size

          // Bedrooms
          if (pathUri[vi].indexOf("-and-more-") >= 0) {
            bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
            sortVal = index_name+"_bedrooms"
          }

          if(a1 === true || a2 === true){
            if (pathUri[vi].indexOf("studio") >= 0) {
              // its studio bedroom
              bedVal = 0
            }
          }


          // Sort by
          if (pathUri[vi].indexOf("sortby-") >= 0) {
            sortVal_filt = pathUri[vi].replace("sortby-", "")

            if (sortVal_filt === "price-asc") {
              sortVal = index_name + "_price_asc"
            }

            if (sortVal_filt === "price-desc") {
              sortVal = index_name + "_price_desc"
            }

            if (sortVal_filt === "size-asc") {
              sortVal = index_name + "_size_asc"
            }

            if (sortVal_filt === "size-desc") {
              sortVal = index_name + "_size_desc"
            }

            // if (sortVal_filt === "most-recent") {
            //     sortVal = index_name + "_recent"
            // }
          }else{
            if(a5 || a6 || a7){
              sortVal = index_name + "_lot_asc"
            }
          } 

          // Page
          if (pathUri[vi].indexOf("page") >= 0) {
            pageVal = pathUri[vi].replace("page-", "")
          }
        }
      }
    }

    // Property type
    if (propertyval) {
      query += `&menu[building]=` + propertyval

      if(propertyval === "investment"){
        sortVal = index_name + "_status_asc"
      }
    }


    // Area
    if (areaVal) {
      areaVal = areaVal.toLowerCase();
      // if(areaVal !== "uk"){
      //   if(areaVal.length < 4) {
      //     areaVal = '"'+areaVal+'"'
      //   }
      // }


      //console.log("areaVal", areaVal);

      //if (areaVal == "london") query += `&query=`
      if (areaVal === "uk") query += `&query=`
      else query += `&query=` + areaVal
    }

    // Price
    if (minpriceVal) {
      query += `&range[price][min]=` + minpriceVal
    }

    if (maxpriceVal) {
      query += `&range[price][max]=` + maxpriceVal
    }

    // Bedrooms
    if (bedVal == 0) {
      query += `&range[bedroom][min]=` + 0
      query += `&range[bedroom][max]=` + 0
    } else if (bedVal < 10) {
      query += `&range[bedroom][min]=` + bedVal
    }

    // Size
    if (minsizeVal) {
      query += `&range[floorarea_min_sort][min]=` + minsizeVal
    }

    if (maxsizeVal) {
      query += `&range[floorarea_min_sort][max]=` + maxsizeVal
    }

    // Page
    if (pageVal) {
      query += `&page=${pageVal}`
    }

    // Sort by
    if (sortVal) {
      query += `&sortBy=` + sortVal
    }

    return qs.parse(query)
}
// URL to searchstate

class PropertySearch extends React.Component {
    constructor(props) {
        super(props);
    }
    //
    state = {
        searchState: urlToSearchState(typeof window !== 'undefined' ? window.location : ''),
        userObjects: [],
        mapview: false,
        isOpen: false,
        popupType: "",
        membDet:"",
        get_property_type: "",
        srch_department: "",
        auctionIdLists: [],
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            this.setState({ searchState: urlToSearchState(this.props.location) })
        }
    }

    getAuctionIdLists = async url => {
      try {
        const { data } = await axios.get(url, {})
        if (data[3]?.all_lot_ids && data[3]?.all_lot_ids.length > 0) {
          this.setState({ auctionIdLists: data[3]?.all_lot_ids })
          console.log("Data:",data)
        }
      } catch (error) {
      //   console.error(error)
      }
    }

    componentDidMount() {
      $("body").removeClass("overflow-hidden");

      if ( (window.location.href).indexOf('property\/') ) {
        // Infinite properties scroll
        $(window).scroll(function() {

          if ( $("#mynextbut").length ) {
            var top_of_element = $("#mynextbut").offset().top;
            var bottom_of_element = $("#mynextbut").offset().top + $("#mynextbut").outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
              $("#mynextbut").trigger( "click" );
               //window.scrollTo({ top: top_of_element-100, behavior: 'smooth' });
                // the element is visible, do something
            }
          }

          if ( $("#myprevbut").length ) {
            var top_of_element = $("#myprevbut").offset().top;
            var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();

            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
              $("#myprevbut").trigger( "click" );
            }
          }
        });
        let url = `${process.env.GATSBY_STRAPI_SRC}/availablelots_ids.json`;
        this.getAuctionIdLists(url);
      }
    }
    // componentDidUpdate(){
    //   console.log("test 1", includeSold)
    //   if(this.state.srch_department === "investment"){
    //     includeSold = false;

    //     console.log("test 2", includeSold)
    //   }
    // }

    onSearchStateChange = searchState => {
        clearTimeout(this.debouncedSetState)
        this.debouncedSetState = setTimeout(() => {
            navigate(searchStateToUrl(this.props, searchState, this.props.locationname), searchState)
        }, updateAfter)
        this.setState({ searchState })
    }

    OpenModal = (popupType) => {
      //console.log("test")
      this.setState({ isOpen: true, popupType: popupType});
    }

    GetPropertyType = (value) => {
      this.setState({ get_property_type: value});
    }
    //

    OpenMember = (popupType, membDet) => {
      this.setState({ isOpen: true, popupType: popupType, membDet:membDet});
    }

    // Map & List View
    listViewMobile = (e) => {
      $(".property-info").removeClass("active")
      $(".mobile-map-view").addClass("active")
      $(".list-mobile").removeClass("active")
      $(".map-mobile").addClass("active")
      this.setState({mapview: false});
    }

    // Search filter text
    searchFilterText = (e) => {
      //console.log("test");
      $(".search_text").toggleClass("active")
      //$(".result-header").toggleClass("searchText")
    }
    // Search filter text

    mapViewMobile = (e) => {
      $(".property-info").addClass("active")
      $(".mobile-map-view").removeClass("active")
      $(".list-mobile").addClass("active")
      $(".map-mobile").removeClass("active")
      this.setState({mapview: true});
    }
    // Map & List View

    // Search filter text
    searchListDepart = (val) => {
      //console.log("searchListDepart", val);
      this.setState({ srch_department: val})
    }
    // Search filter text

    render() {
        // const price = [
        //     { value: "Highest Price First", label: "Highest Price First" },
        //     { value: "Range", label: "Range" },
        // ];

        // Capitalize
        const capitalize = (s) => {
          if (typeof s !== 'string') return ''
          return s.charAt(0).toUpperCase() + s.slice(1)
        }
        // Capitalize

        const searchParams = parseSearchUrl(this.props.ptype, this.props.fullpathname, this.props.pcategorytype, this.props.pcategorytype2);

        const h1 = propertyH1(searchParams, this.props.pcategorytype);
        const h1_intro = propertyH1Intro(searchParams, this.props.pcategorytype);

        const areaName = searchParams.areas;

        const pType = searchParams.pType;

        let propertyType = "";
        if (searchParams.type === "") {
          propertyType = "property "
        } else {
          propertyType = searchParams.type
        }

        var replace_url = this.props.pcategorytype+"_"+pType;

        const actionStr = ACTION_TYPES[replace_url];

        var description = `Explore our latest collection of property ${actionStr} ${capitalize(searchParams.areas)}. Contact Strettons estate agents in ${capitalize(searchParams.areas)} to find the right property for you.`

        if(this.props.pcategorytype === "commercial"){
          description = `Explore our latest collection of commercial property ${actionStr} ${capitalize(searchParams.areas)}. Contact Strettons estate agents in ${capitalize(searchParams.areas)} to arrange a viewing.`
        } else if(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential"){
          description = `Explore our latest collection of auction property available ${actionStr} ${capitalize(searchParams.areas)}. Contact Strettons estate agents in ${capitalize(searchParams.areas)} to get assistance in property auctions.`
        }


        const isPageLoadBg = (typeof this.props.fullpathname === "undefined");

        var split_path = this.props.fullpathname.split('/');

        //console.log("searchParams", searchParams, this.props);

        var property_data = {"department" : split_path[1], "property_address" : searchParams.areas, "min_bed" : searchParams.bedrooms, "max_price" : searchParams.price.max, "property_type" : searchParams.type, "status" : searchParams.pType};

        //console.log("pcategorytype", searchParams.type)

        var pop_slug = "buying";

        if(this.props.pcategorytype === "residential" && this.props.ptype === "sales"){
          pop_slug = "buying";
        } else if(this.props.pcategorytype === "residential" && this.props.ptype === "lettings"){
          pop_slug = "renting";
        } else if(this.props.pcategorytype === "commercial"){
          pop_slug = "commercial";
        } else if(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential"){
          pop_slug = "auctions";
        }

        //console.log("pcategorytype", this.props.pcategorytype, this.props.ptype, pop_slug);

        //console.log("get_property_type", searchParams)

        //console.log("tag", this.props.pcategorytype);

        var split_sort_by = this.props.location.pathname.split('sortby-');
        var sort_by = split_sort_by.length > 1 ? split_sort_by[1] : '';

        //console.log("sortby ==>", sort_by, split_sort_by);



        var department = (this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential" || this.props.pcategorytype === "auctions") ? "auctions" : this.props.pcategorytype;

        var desc_department = (this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential" || this.props.pcategorytype === "auctions") ? "auction" : this.props.pcategorytype;

        var type = (department === "residential" || department === "commercial") ? "Agency" : "Auctions";
        var sub_type = (department === "residential" || department === "commercial") ? "Other" : "Other";

        // var to_user_id = global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.length > 0 && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.filter(item => item.Department.toLowerCase() === department.toLowerCase());
        var to_user_id = DynamicFormID[department];

        //console.log("to_user_id", to_user_id);

        //console.log("srch_department", this.state.srch_department, this.props.pstatustype, this.props.pcategorytype, this.props.ptype)

        var h1_text_replace = h1;

        var script_tag = `<script defer >
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10810490027');
        </script>`;

        return (
            <Layout GQLPage="" Layout="Without_Banner" classNames={"search-header  search-property-header details-header current_catalogue"} popular_search_slug={pop_slug} >
                {(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential") &&
                  <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10810490027"></script>
                  </Helmet>
                }

                {!isPageLoadBg &&
                  <SeoResults title={h1_text_replace.replace(/-/g, ' ').replace('in Uk', 'in UK').replace('Rest Of Uk', 'Rest of UK')} searchParams={searchParams} description={description} location={this.props.fullpathname} pcategorytype={this.props.pcategorytype} srch_department={this.state.srch_department} />
                }

                {(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential") && <BannerBottom />}

                <InstantSearch
                    indexName={index_name}
                    searchClient={searchClient}
                    searchState={this.state.searchState}
                    onSearchStateChange={this.onSearchStateChange}
                    createURL={createURL}
                    routing="true"
                >
                    <div className="d-none">
                        <RefinementList
                            attribute="search_type"
                            defaultRefinement={[this.props.ptype]}
                        />
                        <RefinementList
                            attribute="department"
                            defaultRefinement={[this.props.pcategorytype]}
                        />
                        {(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential") ?
                          (<RefinementList
                            attribute="availablelot_status"
                            defaultRefinement={["Unsold", "ReferToAuctioneer","no_status", "NoBids"]}
                          />
                          )
                          :
                          (this.state.srch_department === "investment" ?
                            <RefinementList
                              attribute="status"
                              defaultRefinement={[this.props.pstatustype, "Under Offer", "Sold"]}
                            /> :
                            <RefinementList
                              attribute="status"
                              defaultRefinement={[this.props.pstatustype, "Under Offer"]}
                            />
                          )
                        }
                        {(this.props.pcategorytype === "auction_commercial" || this.props.pcategorytype === "auction_residential") ?
                          (<RefinementList
                            attribute="auctionId"
                            defaultRefinement={this.state.auctionIdLists}
                          />
                          )
                          : ""
                        }

                        {
                          this.props.tag === "auctionSales" ?
                          <RefinementList
                            attribute="department"
                            defaultRefinement={[this.props.pcategorytype2]}
                          /> : ""
                        }
                        {
                          this.props.pcategorytype === "auction_residential" || this.props.pcategorytype === "auction_commercial" ?
                          <>
                            <RefinementList
                              attribute="publish"
                              defaultRefinement={[true]}
                            />
                            {/* <RefinementList
                                attribute="status"
                                defaultRefinement={["ReferToAuctioneer", "withdrawn", "Unsold", "NoBids"]}
                            /> */}
                          </>
                          : ""
                        }
                        <RefinementList
                            attribute="publish"
                            defaultRefinement={[true]}
                        />
                    </div>
                    <Configure
                        hitsPerPage={15}
                    />



                    <Filter type={this.props.ptype} department={this.props.pcategorytype} tag={this.props.tag} sortBy={sort_by} propertyType={propertyType} searchListDepart={this.searchListDepart} areas={searchParams.areas} srch_department={this.state.srch_department}  />

                    <div className="d-none">
                        <SearchBox  />
                        <CustomminpriceSelectbox attribute="price" type={this.props.ptype} />
                        <CustommaxpriceSelectbox attribute="price" type={this.props.ptype} />
                        <CustomminsizeSelectbox attribute="floorarea_min_sort" type={this.props.ptype} />
                        <CustommaxsizeSelectbox attribute="floorarea_min_sort" type={this.props.ptype} />
                        <CustombuildingSelectbox attribute="building" department={this.props.pcategorytype} ptype={this.props.ptype} />
                        <CustombedroomSelectbox attribute="bedroom" />
                    </div>


                    {/* <Sales /> */}
                    <section className="sales">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="choose mbl_sort d-block d-lg-none">
                                      {/* <span className="sm-text">Sort:</span> */}
                                      <Form.Group className="price">
                                          <CustomSortByFilter
                                              defaultRefinement={index_name}
                                              className="search-sory-by"
                                              items={this.props.pcategorytype === "commercial" ? SortByCommercial : this.props.pcategorytype === "residential" ? SortByValues : SortByAuction}
                                              placeholder={this.props.pcategorytype === "commercial" ? "Largest Size First" : this.props.pcategorytype === "residential" ? "Highest Price First" : (this.props.tag === "auction" || this.props.tag === "auctionSales") ? "Lowest Lot First" : "Recent First"}
                                          />
                                          <i className="icon-down"></i>
                                      </Form.Group>
                                    </div>

                                    <div className="sales-info">
                                        <div className="sales-details">
                                            <div className={`filter-menu ${this.props.pcategorytype}`}>
                                                <h1>{h1_text_replace.replace(/-/g, ' ').replace('in Uk', 'in UK').replace('Rest Of Uk', 'Rest of UK')} <a href="javascript:void(0)" onClick={this.searchFilterText}><i className="info-icon"></i></a></h1>

                                                <h2 style={{display:"none"}}>{this.props.tag === "commercial" ? "Commercial": ""} Property {this.props.ptypetag == "for-sale" ? "for sale" : "to rent"} in London</h2>
                                                <span className="sm-info"> <CustomStats /> {this.props.tag === "commercial" ? "commercial": (this.props.tag === "auctionSales" ? "auction" : this.props.pcategorytype.replace("_", " "))} properties {this.props.ptypetag == "for-sale" ? "for sale" : "to rent"}</span>
                                            </div>
                                            <div className="alert">
                                                {
                                                  (this.props.pcategorytype === "commercial" || this.props.pcategorytype === "residential") ?
                                                  <div className="msg-details d-flex align-items-center" onClick={() => this.OpenModal("create_email")}>
                                                      <a href="javascript:;" className="d-flex align-items-center">
                                                          <i className="icon-search-mail d-inline-block me-2"></i>
                                                      </a>
                                                      <span className="d-md-block d-none">Register your search</span>
                                                      <span className="d-md-none d-block">Register your search</span>
                                                  </div>
                                                  :
                                                  <div className="msg-details d-flex align-items-center">
                                                      <Link to={CustomLinks.newsletter_subscription} className="d-flex align-items-center">
                                                          <i className="icon-search-mail d-inline-block me-2"></i>
                                                          <span className="d-md-block d-none">Register to receive auction updates</span>
                                                          <span className="d-md-none d-block">Register to receive auction updates</span>
                                                      </Link>
                                                  </div>
                                                }

                                                {
                                                  this.props.pcategorytype === "commercial" ? (
                                                  <div className="checkbox-block d-md-flex align-items-center">
                                                    {
                                                    <CustomFitToggleRefinement
                                                        attribute="isFitted"
                                                        label="Fitted"
                                                        value={true}
                                                        defaultRefinement={isFitted}
                                                        property_type={property_data.property_type}
                                                    />
                                                    }

                                                    {this.props.ptype === "lettings" ?
                                                      <>
                                                        <CustomToggleRefinement
                                                          attribute="status"
                                                          label="Include let"
                                                          value={['let']}
                                                          defaultRefinement={includeSold}
                                                        />

                                                        {/* <div className="d-none">
                                                          <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include Offer"
                                                              value={['Under Offer']}
                                                              defaultRefinement={includeSold}
                                                          />
                                                        </div> */}
                                                      </>

                                                      :
                                                      <>
                                                        {this.state.srch_department === "investment" ?
                                                        <>
                                                          <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include sold"
                                                              value={['Sold']}
                                                              defaultRefinement={!includeSold}

                                                          />

                                                          {/* <div className="d-none">
                                                            <CustomToggleRefinement
                                                                attribute="status"
                                                                label="Include Offer"
                                                                value={['Under Offer']}
                                                                defaultRefinement={!includeSold}
                                                            />
                                                          </div> */}

                                                        </>
                                                        :
                                                        <>
                                                          <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include sold"
                                                              value={['Sold']}
                                                              defaultRefinement={includeSold}
                                                          />

                                                          {/* <div className="d-none">
                                                            <CustomToggleRefinement
                                                                attribute="status"
                                                                label="Include Offer"
                                                                value={['Under Offer']}
                                                                defaultRefinement={includeSold}
                                                            />
                                                          </div> */}
                                                        </>
                                                        }
                                                      </>
                                                  }
                                                  </div>
                                                  )
                                                  : this.props.pcategorytype === "residential" && (
                                                  <div className="checkbox-block d-md-flex align-items-center">
                                                  {this.props.ptype === "lettings" ?
                                                      <>
                                                        <CustomToggleRefinement
                                                          attribute="status"
                                                          label="Include let"
                                                          value={['let']}
                                                          defaultRefinement={!includeSold}
                                                        />

                                                        {/* <div className="d-none">
                                                          <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include Offer"
                                                              value={['Under Offer']}
                                                              defaultRefinement={includeSold}
                                                          />
                                                        </div> */}
                                                      </>
                                                      :
                                                      <>
                                                        <>
                                                          <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include sold"
                                                              value={['Sold']}
                                                              defaultRefinement={!includeSold}
                                                          />

                                                          {/* <div className="d-none">
                                                            <CustomToggleRefinement
                                                                attribute="status"
                                                                label="Include Offer"
                                                                value={['Under Offer']}
                                                                defaultRefinement={includeSold}
                                                            />
                                                          </div> */}
                                                        </>
                                                        {/* {
                                                          this.props.pcategorytype === "auction_residential" || this.props.pcategorytype === "auction_commercial" ?
                                                            <CustomToggleRefinement
                                                                attribute="status"
                                                                label="Include sold"
                                                                value={['Sold']}
                                                                defaultRefinement={includeSold}
                                                            />
                                                            :
                                                            <CustomToggleRefinement
                                                              attribute="status"
                                                              label="Include sold"
                                                              value={['UNDER OFFER - For Sale']}
                                                              defaultRefinement={includeSold}
                                                          />
                                                        } */}
                                                      </>
                                                  }
                                                  </div>)
                                                }

                                                <div className="location d-lg-none">
                                                    <span className="list-mobile d-flex align-items-center" onClick={this.listViewMobile}>
                                                      <a href="javascript:void(0)">
                                                          <i className="icon-map">
                                                          </i>
                                                      </a>
                                                      Grid
                                                    </span>
                                                    <span className="map-mobile d-flex align-items-center active" onClick={this.mapViewMobile}>
                                                      <a href="javascript:void(0)">
                                                          <i className="icon-map">
                                                          </i>
                                                      </a>
                                                      Map
                                                    </span>
                                                </div>
                                                <div className="choose d-none d-lg-flex">
                                                    <span className="sm-text">Sort:</span>

                                                    <Form.Group className="price">
                                                        {/* <SelectOption placeHolder="Highest Price First" options={price}
                                                        /> */}
                                                        <CustomSortByFilter
                                                            defaultRefinement={index_name}
                                                            className="search-sory-by"
                                                            items={this.props.pcategorytype === "commercial" ? SortByCommercial : this.props.pcategorytype === "residential" ? SortByValues : SortByAuction}
                                                            placeholder={this.props.pcategorytype === "commercial" ? "Largest Size First" : this.props.pcategorytype === "residential" ? "Highest Price First" : (this.props.tag === "auction" || this.props.tag === "auctionSales") ? "Lowest Lot First" : "Recent First"}
                                                        />
                                                        <i className="icon-down"></i>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            {/* {
                                              this.props.tag === "commercial" ? ""
                                              :
                                              <div className="checkbox-block d-md-none d-flex align-items-center mb-4">
                                                {this.props.ptype === "lettings" ?
                                                    <CustomToggleRefinement
                                                        attribute="status"
                                                        label="Include Let"
                                                        value={['UNDER OFFER - To Let']}
                                                        defaultRefinement={includeSold}
                                                    />
                                                    :
                                                    <CustomToggleRefinement
                                                        attribute="status"
                                                        label="Include Sold"
                                                        value={['UNDER OFFER - For Sale']}
                                                        defaultRefinement={includeSold}
                                                    />
                                                }
                                              </div>
                                            } */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>


                        </Container>
                    </section>

                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <section className="property-info search_res">
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="property-wrapper d-lg-flex justify-content-lg-start">
                                            <div className="property-img-wrapper d-lg-flex justify-content-lg-start">

                                                <p className="search_text">
                                                  Explore our wide range of {h1_intro.replace(/-/g, ' ').replace('in uk', 'in UK')} with Strettons Estate Agents. To filter these results, use our filter above, or for more information about {desc_department} properties {this.props.pstatustype.toLowerCase().replace('let', 'rent')} in {toTitleCase(areaName)}, please <Link to={'/contact-us/'}>contact us.</Link>
                                                </p>

                                                <CustomInfiniteHits location={this.props.fullpathname} tag={this.props.ptypetag} auctionTag={this.props.tag} OpenMember={this.OpenMember} includeSold={includeSold} srch_depart={this.state.srch_department}  />

                                                <CustomNoStats location={this.props.fullpathname} status={this.props.pstatustype} department={this.props.pcategorytype} department2={this.props.pcategorytype2} OpenMember={this.OpenMember} />

                                                {(this.props.tag === "auction" || this.props.tag === "auctionSales") &&
                                                  <NoticeBidder />
                                                }
                                            </div>
                                            <div className="map-section d-none d-lg-block">
                                                <div className="map-view-wrap">
                                                    {(!isMobileOnly) &&
                                                        <MapResultsDesktop includeSold={includeSold} srch_depart={this.state.srch_department} status={this.props.pstatustype} department={this.props.pcategorytype}
                                                            ptype={this.props.ptype === "sales" && this.props.pcategorytype === "residential" ? "myResSaleProp"
                                                            : this.props.ptype === "lettings" && this.props.pcategorytype === "residential" ? "myResRentProp" : ''
                                                            }
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </ScrollAnimation>

                    <div className="mobile-map-view active">
                      {
                      (isMobile && this.state.mapview) &&
                          <>
                            <Configure
                              hitsPerPage={2000}
                            />
                            {/* <MapResultsDevice /> */}
                            <MapResultsMobile includeSold={includeSold} srch_depart={this.state.srch_department}
                                ptype={this.props.ptype === "sales" && this.props.pcategorytype === "residential" ? "myResSaleProp"
                                : this.props.ptype === "lettings" && this.props.pcategorytype === "residential" ? "myResRentProp" : ''
                                }
                            />
                          </>
                      }
                    </div>

                </InstantSearch>

                {/* ===== Modal Forms =====*/}
                <Modal
                    id="popup_modal_form"
                    show={this.state.isOpen}
                    onHide={() => this.setState({isOpen: false})}
                    backdrop="static"
                    keyboard={false}
                    className="member-contact-modal"
                >
                  {
                    this.state.popupType === "create_email" && <>
                      <Modal.Header closeButton className="contact-close-btn memberPopup">
                        <Modal.Title className="w-100">
                          <h3 className="text-center content-primary-color mb-0">Register for new listings</h3>
                          <p className="text-center">Share your property search details and we will email you matching properties. You can unsubscribe anytime.</p>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <CreateEmailAlert location={this.props.location} propertyData={property_data} type={type} sub_type={sub_type} to_user_id={to_user_id} department={toTitleCase(this.props.pcategorytype)} />
                        {/* {
                          this.props.pcategorytype === "residential" ? <CreateEmailAlert location={this.props.location} propertyData={property_data} type={type} sub_type={sub_type} to_user_id={to_user_id} department={this.props.pcategorytype} />
                          : <CreateEmailAlertCommercial location={this.props.location} propertyData={property_data} type={type} sub_type={sub_type} to_user_id={to_user_id} department={this.props.pcategorytype} />
                        } */}
                      </Modal.Body>
                    </>
                  }

                  {
                    this.state.popupType === "Member_Form" && <>
                      <Modal.Header closeButton className="contact-close-btn memberPopup">
                        <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {this.state.membDet.Name}</h3></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MemberForm to_email_id={this.state.membDet.Email_ID} to_user_id={this.state.membDet.Form_Queue_ID} formName={`Individual Contact - ${this.state.membDet.Name}`} formLabel={`Person ${this.state.membDet.Name}`} department={this.props.pcategorytype} />
                      </Modal.Body>
                    </>
                  }
                </Modal>

            </Layout>
        )
    }
}

export default PropertySearch
