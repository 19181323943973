/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr, getSizeStr, toTitleCase, propertyTypesH1 } from "./utils"

 import { useLocation } from "@reach/router"
 
 import _ from "lodash"
 export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils";
 
 const SeoResults = ({ description, lang, meta, title, searchParams, location, pcategorytype, srch_department }) => {
 
   const {areas, bedrooms, pType, type, price, commType, mustInclude, size } = searchParams;

   var replace_url = pcategorytype+"_"+pType;

   var h1_type = type ? propertyTypesH1[type] : "";

   const actionStr = ACTION_TYPES[replace_url];

   const actionStrDesc = ACTION_DESC_TYPES[replace_url];

   const priceStr = getPriceStr(price)
   const sizeStr = getSizeStr(size)

   const bedRooms = searchParams.bedrooms
 
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let propertyType = "";


   if(h1_type === "") {
     propertyType = "property"
   } else if(h1_type == "apartment") {
     propertyType = "apartments"
   } else if(h1_type == "house") {
     propertyType = "houses"
   } else if(h1_type == "bungalow") {
     propertyType = "bungalows"
   } else {
     propertyType = capitalize(h1_type.replace(/-/g, ' '))
   }
 
   let mustIncludeTitle = ""
 
   if ( mustInclude ) {
     mustIncludeTitle = " with "+mustInclude.join(' and ').replace("parking", "Parking").replace("chain", "Chain").replace("outside", "Outside").replace(/-/g, ' ')
   }
 
   let desc = "";
   desc = "Discover a wide range of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + mustIncludeTitle+" with Strettons Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + toTitleCase(areas.replace(/-/g, ' ')) + ". please contact us.";
 
   if ('UK' !== areas) {
     desc = "Discover a wide range of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + mustIncludeTitle+" with Strettons Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + toTitleCase(areas.replace(/-/g, ' ')) + ". please contact us.";
   }
 
   if ('sales' === pType) {
     desc = "Discover a wide range of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + mustIncludeTitle+" with Strettons Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + toTitleCase(areas.replace(/-/g, ' ')) + ". please contact us.";
 
     if ('UK' !== areas) {
         desc = "Discover a wide range of "+ propertyType + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + mustIncludeTitle+" with Strettons Estate Agents. To filter these results, use our filter below, or for more information about "+ pType+ " property in " + toTitleCase(areas.replace(/-/g, ' ')) + ". please contact us.";
     }
   } else {
     // price
     if (price.min || price.max){
       desc = propertyType + actionStr + areas + "."
     }
 
   }

   let meta_desc = description;

   //console.log("areas", areas, searchParams)

   var area_capitalize = capitalize(areas.replace(/-/g, ' '))

    if(pcategorytype){
        var department = "";
        if(pcategorytype === "commercial"){
          department = "commercial";
        } else if(pcategorytype === "auction_commercial" || pcategorytype === "auction_residential"){
          department = "auction";
        }
        
        if(pcategorytype === "commercial" || pcategorytype === "residential"){
          if(areas.toLowerCase() !== "london"){
            meta_desc = `Explore our range of ${department} property ${actionStrDesc} in ${area_capitalize}. Contact our property experts to find the right property ${actionStrDesc} in ${area_capitalize}.`
          }
          
        } 
        
        if(pcategorytype === "auction_commercial" || pcategorytype === "auction_residential"){
          if(areas.toLowerCase() !== "uk"){
            meta_desc = `Explore our range of ${department} property available ${actionStrDesc} in ${area_capitalize}. Contact our property experts to get your desired property through auction in ${area_capitalize}.`
          }
        }

        if(h1_type){
          meta_desc = `Looking for a ${h1_type.replace(/-/g, ' ').toLowerCase()} ${actionStrDesc} in ${area_capitalize}? Strettons ${department} estate agents are here to help.`
        }

        var bed_room = bedRooms === 0 ? "studio" : (bedRooms >= 1 && "more than "+bedRooms+" bedrooms");

        if(bed_room){
          meta_desc = `Explore property ${actionStrDesc} in ${area_capitalize} with ${bed_room} here. Contact our expert estate agents today to get assistance in finding the right property in ${area_capitalize}.`
        }

        if(priceStr) {
          if(pcategorytype === "commercial" || pcategorytype === "residential"){
            meta_desc = `Locate the right property ${actionStrDesc} in ${area_capitalize} ${priceStr} with Strettons estate agents. Book an appointment with one of our property experts in ${area_capitalize}.`
          } else{
            meta_desc = `Find the right auction property ${actionStrDesc} in ${area_capitalize} ${priceStr} with Strettons estate agents. Book an appointment with one of our property experts in ${area_capitalize}.`
          }
        }

        if(sizeStr){
          meta_desc = `Identify the ${department} property ${actionStrDesc} in ${area_capitalize} ${sizeStr}. Book a viewing with Strettons ${department} estate agents.`
        }

        if(priceStr && sizeStr){
          meta_desc = `Check out the latest collection of ${department} properties ${actionStrDesc} in ${area_capitalize} ${priceStr} ${sizeStr} with Strettons estate agents in ${area_capitalize}.`
        }

        if(h1_type && priceStr){
          meta_desc = `Check out the latest collection of ${h1_type.replace(/-/g, ' ').toLowerCase()} ${actionStrDesc} in ${area_capitalize} ${priceStr} with Strettons estate agents in ${area_capitalize}.`
        }

        if(priceStr && bed_room){
          meta_desc = `Explore the list of property ${actionStrDesc} in ${area_capitalize} with ${bed_room} ${priceStr} with Strettons and request a viewing for the property that interests you.`
        }

        if(h1_type && bed_room){
          meta_desc = `Find the latest collection of ${h1_type.replace(/-/g, ' ').toLowerCase()} ${actionStrDesc} in ${area_capitalize} with ${bed_room}. Contact Strettons estate agents and letting agents in ${area_capitalize}, to arrange a viewing.`
        }

        if(h1_type && sizeStr){
          meta_desc = `Looking for a ${h1_type.replace(/-/g, ' ').toLowerCase()} ${actionStrDesc} in ${area_capitalize} ${sizeStr}? Strettons ${department} estate agents are here to help.`
        }

        if(h1_type && priceStr && (bed_room || sizeStr)){
          meta_desc = `Explore ${bed_room ? bed_room : ''} ${h1_type.replace(/-/g, ' ').toLowerCase()} ${actionStrDesc} ${sizeStr ? sizeStr : ''} in ${area_capitalize} ${priceStr} available with Strettons. Contact one of our estate agents for assistance in finding your property in ${area_capitalize}.`
        }

    }
 
     //
     let titlePropertyType = ""

     //console.log("seo_type", type, pcategorytype)
 
     if(h1_type == "") {
         titlePropertyType = ""
     } else if(h1_type == "apartment") {
         titlePropertyType = "Apartments "
     } else if(h1_type == "house") {
         titlePropertyType = "Houses "
     } else if(h1_type == "maisonette") {
         titlePropertyType = "Maisonette "
     } else if(h1_type == "bungalow") {
         titlePropertyType = "Bungalows "
     } else if(h1_type == "land") {
         titlePropertyType = "Land "
     } else {
         titlePropertyType = h1_type+" "
     }
 
     let bedroomType = ""

     let department_ttl = "";

     if(srch_department === "investment"){
      department_ttl = titlePropertyType.toLowerCase() + srch_department+" property";
     } else{
      department_ttl = titlePropertyType + pcategorytype === "residential" ? "property" : pcategorytype+" property";
     }
 
     if ( bedrooms ) {
       bedroomType = bedrooms + ' + Bedroom '
     }
 
    //  let descTitle = bedroomType + toTitleCase(department_ttl.replace(/-/g, ' ')) + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + priceStr + mustIncludeTitle
    let descTitle = title;

     //
 
   // console.log("Props Desc => ", desc);
 
   description = desc;

   var { pathname } = useLocation()

   var currentUrl = '';
   currentUrl = process.env.GATSBY_SITE_URL+pathname;

   // set no index for result pagination
   let hasPagination = false;
   if (pathname !== undefined && pathname) {
     let pageStr = pathname.split('page-');
     hasPagination = pageStr[1] || false;
     if (pageStr[0] !== undefined) {
      currentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
     }
   }
 
   if (!currentUrl.endsWith('/'))
   currentUrl = currentUrl + '/';
 
   //remove multiple slashes
   currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1");
   //set lowercase url
   currentUrl = currentUrl.toLowerCase();

 
   let metaItems = [
     {
       name: `description`,
       content: meta_desc,
     },
     {
       property: `og:title`,
       content: descTitle,
     },
     {
       property: `og:description`,
       content: meta_desc,
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
      name: `og:url`,
      content: currentUrl,
     },
     {
       name: `twitter:card`,
       content: `summary`,
     },
     {
       name: `twitter:creator`,
       content: `Strettons`,
     },
     {
       name: `twitter:title`,
       content: descTitle,
     },
     {
       name: `twitter:description`,
       content: meta_desc,
     },
   ].concat(meta);
 

  //  if ( hasPagination ) {
  //    metaItems.push({
  //      name: `robots`,
  //      content: `noindex`,
  //    })
  //  }
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
        {
          href: currentUrl,
          rel: "canonical"
        }]}
       title={descTitle}
       titleTemplate={`%s | Strettons`}
       meta={metaItems}
     />
   )
 }
 
 SeoResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 SeoResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default SeoResults
 