import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";

import { CustomLinks } from "../../common/utils";

const MarketAds = (props) => {

    const data = useStaticQuery(graphql`
    query MarketAds {
        glstrapi {
          globalModule {
            Search_Result_Market_Ads {
              CTA_Link
              CTA_Label
              Title
              Content
              Type
            }
          }
        }
      }
  `)

  var globalModule = data.glstrapi?.globalModule;
    
      var department = (props?.hit?.department === "auction_residential" || props?.hit?.department === "auction_commercial") ? "auctions" : props?.hit?.department
    return (
    <React.Fragment>
        {
            globalModule?.Search_Result_Market_Ads?.filter(item => item.Type.toLowerCase() === department).map((item, index)=>{
                if(index === 0){
                    //console.log("item", item);
                    return(
                        <div className="property-cross-selling-box">
                            <Row className="align-items-center">
                                <Col xl={9} lg={7} className="mb-lg-0 mb-4">
                                    <div>
                                    <h6 className="pb-2">{item.Title}</h6>
                                    <p className="mb-0">{item.Content}</p>
                                    </div>
                                </Col>
                                <Col xl={3} lg={5}>
                                    <Link to={item.CTA_Link} state={{"department":props?.hit?.department ? props?.hit?.department : ""}} className="btn btn-primary mb-0"> {item.CTA_Label}</Link>  
                                </Col>
                            </Row>
                        </div>
                    )
                }
                
            })
        }
        
    </React.Fragment>
  );
};


export default MarketAds;