import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Lightbox from 'react-image-lightbox';
import ShowMoreText from 'react-show-more-text';
import 'react-image-lightbox/style.css';
import FsLightbox from 'fslightbox-react';
import HTMLReactParser from 'html-react-parser';
import _ from "lodash"
import { ShowProcessedImage } from '../../common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import "./PropertyInfo.scss";
import NoImage from "../../../images/no-image.png"
import { People_Email, People_NegoID } from "../../../queries/common_use_query"
import { CustomLinks } from "../../common/utils"
import { gtag_report_conversion } from "../../common/pageUtils"
import { getPropAddr, toTitleCase } from "../../property-search/utils"
import dateFormat from "dateformat";
import NegoImg from "./negoImg";
import { ImageModule } from "../../../modules/Image_Module"

// Negotiator details
const NegoDetails = (email_id) => {

  const { loading, error, data } = People_Email(email_id);

  // console.log("nego_info ==>", data);
  return data;
}

const NegoDetailsAuction = (nego_id) => {

  const { loading, error, data } = People_NegoID(nego_id);

  // console.log("nego_info =====>", data);
  return data;
}
// Negotiator details

const PropertyList = (props) => {
  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [negoID, setNegoID] = useState('');

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }

  const [toggler, setToggler] = useState(false);
  const [availableLots, setAvailableLots] = useState((sessionStorage.getItem('avaiable_lots') !== null) ? JSON.parse(sessionStorage.getItem('avaiable_lots')) : []);

  // var propertyLightImages = props?.DeskImg && props?.DeskImg?.map(img => img["400x260"]);
  // Property images lightbox


  // Negotiator details
  var nego_info = props?.NegoName && props?.NegoName[0]?.email && NegoDetails(props.NegoName[0].email);

  var nego_info_auction = (props?.Department === "auction_residential" || props?.Department === "auction_commercial") && props?.NegoName && NegoDetailsAuction(props?.NegoName.length > 0 && props?.NegoName?.split('/')[0]);


  //console.log("nego_info_auction-----", props.Address, props?.NegoName?.length, props?.NegoName?.split('/')[0], nego_info_auction);
  // Negotiator details

  var addr = props?.Address ? getPropAddr(props.Address, props?.postcode) : "";

  var addr_commercial = [];
  var addr_commercial2 = [];
  if (props?.address_json) {
    //props?.address_json.address2 && addr_commercial.push(props?.address_json.address2.toUpperCase());
    props?.address_json.address0 && addr_commercial.push(props?.address_json.address0);
    props?.address_json.address1 && addr_commercial.push(props?.address_json.address1);
    props?.address_json.address3 && addr_commercial2.push(props?.address_json.address3);
    props?.address_json.address4 && addr_commercial2.push(props?.address_json.address4);
    props?.address_json.postcode && addr_commercial2.push(props?.address_json.postcode);
  }

  var price_pcm = props?.searchType && props?.searchType === "lettings" ? "PCM" : "";
  var earlyInvestment = false;
  if (props.auctionId && availableLots.length > 0) {
    if (availableLots[2].future_lot_id.includes(`${props.auctionId}`) && props.availablelotStatus == "NoBids") {
      earlyInvestment = true;
    }
  }

  const ImageRenderList = ({ item, imagename }) => {
    let processedImages = JSON.stringify({});
    if (item?.imagetransforms?.Image_Transforms) {
      processedImages = item?.imagetransforms?.Image_Transforms;
    }
    return (
      <ImageModule ImageSrc={item.Image} title={item.Name} altText={item.Name + " - Strettons"} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={item.id} />
    )
  }

  return (
    <React.Fragment>
      <div className={`property-img mysingleproperty ${props.className} ${earlyInvestment ? 'earlyInvestment' : ''}`} id={`${props?.ObjectIDprop}`} data-objectid={props?.ObjectIDprop} data-lat={props?.GeoLat} data-lng={props?.GeoLng}>
        <div className="img-zoom">
          <Link to={props.propertyDetailsLink} onClick={() => gtag_report_conversion(props.propertyDetailsLink)} className="zoom" state={{ "lot_no": props.lotNumber }}>
            {
              props.Type === "No_Result" && props.DeskImg ?
                <picture >
                  <source media="(min-width:992px)" srcSet={props.DeskImg} />
                  <img src={props.DeskImg} alt={props.Address + " -  Strettons"} />
                </picture>
                :
                props.DeskImg && props.DeskImg.length != 0 ?
                  (
                    <ShowProcessedImage images={props.DeskImg[0]}
                      attr={{ className: 'mb-0 img-fluid', alt: props.Address + " -  Strettons" }}
                      transforms={imageConfig.property["searchResults_auctions"]['sizes']} />
                  )
                  :
                  <picture >
                    <source media="(min-width:992px)" srcSet={NoImage} />
                    <img src={NoImage} alt="No image" />
                  </picture>
            }
            {
              props?.Offer && (props.Offer !== "Unsold" && props.Offer !== "For Sale" && props.Offer !== "To Let" && _.toLower(props?.Offer.replace(" ", "").replace("-", "")) != "nobids") && earlyInvestment === false &&
              <div className="offer-text">

                {props.Offer === "UNDER OFFER - For Sale" || props.Offer === "UNDER OFFER - To Let" ? "UNDER OFFER" : props.Offer.replace('ReferToAuctioneer', "Refer To Auctioneer")}

              </div>
            }
            {earlyInvestment &&
              <div className="offer-text early_investment">
                Early investment
              </div>
            }

            {/* <div className="gallery-info">
              <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}></a>
              <a href="javascript:void(0)" onClick={() => setToggler(!toggler)}></a>
              <a href="javascript:void(0)">
                <i className="icon-gallery"></i>
                <span className="no-count">1/{props?.Count?.length}
                </span>
              </a>
            </div> */}
          </Link>
        </div>

        <div className={`property-details ${props?.depart_cls}`}>
        <h2 className="equalHeights">
            <Link to={props.propertyDetailsLink} onClick={() => gtag_report_conversion(props.propertyDetailsLink)} className="link-title" state={{ "lot_no": props.lotNumber }}>
              {props?.Department === "residential" ?
                <>
                  £{props?.PropertyPrice?.toLocaleString()} {price_pcm}<br />
                  <address className="commercial_title mt-2">{props?.Title}</address>
                </>
                : props?.Department === "commercial" ?
                  <>
                    {/* {props?.Sub_Area && props?.Sub_Area?.length > 36 ? 
                        (props?.Sub_Area?.toLowerCase()).substr(0, 36)+"..."
                        : (props?.Sub_Area?.toLowerCase())
                      }*/}

                    <div>
                      {/* {props?.Sub_Area ? props?.Sub_Area?.toLowerCase()  : ""}{((props?.Sub_Area && props?.Sub_Area !== "") && addr !== "") ? ", " : ''} */}
                      {/* {addr ? addr : props?.Address && props?.Address} */}
                      {props?.address_json.address2 && HTMLReactParser(props?.address_json.address2.toUpperCase() + " ")}
                    </div>
                    <div>
                    {addr_commercial ? addr_commercial.toString(", ").replace(/,/g, ', ') : ''}
                    </div>
                    <div>
                    {addr_commercial2 ? addr_commercial2.toString(", ").replace(/,/g, ', ') : ''}
                    </div>
                  </>
                  :
                  <>
                    <address className="lot_number">{props?.auctionDate ? dateFormat(props?.auctionDate, "dd mmm yy") : ""} {(props?.auctionDate && props?.lotNumber) ? " - " : ""} {props.lotNumber && <>Lot {props.lotNumber}</>}</address>

                    {/* {props?.extra?.tagline?.length > 36 ? 
                        (props?.extra?.tagline?.toLowerCase()).substr(0, 36)+"..." 
                        : (props?.extra?.tagline?.toLowerCase())
                      } */}
                    <span className="heading_ttl auction_ttl">{addr ? addr : props?.Address ? props?.Address : ''}</span>
                  </>
              }
            </Link>
          </h2>

          {props?.Department === "residential" ?
            <address className="commercial_title">{addr ? addr : props?.Address}</address>
            :
            props?.Department === "commercial" ?
              <>
                {
                  <address className="commercial_title">
                    {props?.introtext?.substring(0, 70)} <Link to={props.propertyDetailsLink} onClick={()=>gtag_report_conversion(props.propertyDetailsLink)} className="link-descrip" state={{"lot_no":props.lotNumber}}>View more
</Link>
                  </address>
                }


                {
                  (props?.floorarea_min || props?.floorarea_max) &&
                  <div className="crop">
                    <a><i className="icon-crop"></i></a>
                    {props?.floorarea_min.toLocaleString()}
                    {(props?.floorarea_min !== "" && props?.floorarea_max !== "") && props?.floorarea_max !== props?.floorarea_min && <> to </>}
                    {props?.floorarea_max !== props?.floorarea_min && props?.floorarea_max.toLocaleString()}
                    {' '}{props?.floorarea_type.toLocaleString().replace('sqft', 'sq ft').replace('sqm', 'sq m')}
                  </div>
                }

                <div className="price">
                  <a><i className="icon-price-yellow"></i></a>
                  {props?.PropertyPrice ?
                    (props?.PropertyPrice?.toLocaleString() + (props?.rentPriceMetric ? ` per ${props?.rentPriceMetric.replace('sqft', 'sq ft').replace('sqm', 'sq m')}` : ""))
                    : toTitleCase(props?.status) + " on Application"}
                </div>

                {
                  props?.building?.length > 0 &&
                  <div className="office office-info">
                    <a><i className="icon-office"></i></a>
                    {(props.building.toString()).replace(/,/g, ', ').replace(/-/g, ' ')}
                  </div>
                }

              </>
              :
              <>
                  <address className="commercial_title">
                    {props?.extra?.tagline ? props?.extra?.tagline.substring(0, 40) : ''} <Link to={props.propertyDetailsLink} onClick={() => gtag_report_conversion(props.propertyDetailsLink)} className="link-title link-descrip" state={{ "lot_no": props.lotNumber }}>View more</Link>
                  </address>

                {
                  props?.building?.length > 0 &&
                  <div className="price office mb-2">
                    <a><i className="icon-office"></i></a>
                    {(props.building.toString()).replace(/,/g, ', ').replace(/-/g, ' ')}
                  </div>
                }

                {
                  props?.extra?.guidePrice ?
                    <div className="office office-info">
                      <a><i className="icon-price-yellow"></i></a>
                      {"Guide Price " + props?.extra?.guidePrice.toUpperCase() + "*"}
                    </div>
                    :
                    <div className="office office-info">
                      <a><i className="icon-price-yellow"></i></a>
                      {props?.PropertyPrice && "Guide Price £" + props?.PropertyPrice?.toLocaleString() + "*"}
                    </div>
                }
              </>
          }

          { 
            props?.Department === "auction_residential" || props?.Department === "auction_commercial" ?
              nego_info_auction?.peoples?.length > 0 &&
              <div className="contact-person ">
                <div className="contact-img">
                  <picture>
                    <Link to={CustomLinks.our_people + CustomLinks.find_team + "/" + nego_info_auction?.peoples[0]?.URL + "/"} onClick={() => gtag_report_conversion(props.propertyDetailsLink)}>
                      <NegoImg nego_info={nego_info_auction}></NegoImg>
                    </Link>
                  </picture>
                </div>
                <div className="contact-info">
                  <h3><Link to={CustomLinks.our_people + CustomLinks.find_team + "/" + nego_info_auction?.peoples[0]?.URL + "/"} onClick={() => gtag_report_conversion(props.propertyDetailsLink)}>{nego_info_auction?.peoples[0]?.Name}</Link></h3>
                  <a href={"tel:" + nego_info_auction?.peoples[0]?.Phone_Number} className="tel">
                    {nego_info_auction?.peoples[0]?.Phone_Number}
                    {" "}
                  </a>                  
                </div>
              </div>
              :
              nego_info?.peoples?.length > 0 &&
              <div className="contact-person">
                <div className="contact-img">
                  <picture>
                    <Link to={CustomLinks.our_people + CustomLinks.find_team + "/" + nego_info?.peoples[0]?.URL + "/"} onClick={() => gtag_report_conversion(props.propertyDetailsLink)}>
                      <ImageRenderList item={nego_info?.peoples[0]} imagename={"peoples.Image.team_avatar"} />
                    </Link>
                  </picture>
                </div>
                <div className="contact-info">
                  <h3>
                    <Link to={CustomLinks.our_people + CustomLinks.find_team + "/" + nego_info?.peoples[0]?.URL + "/"} onClick={() => gtag_report_conversion(props.propertyDetailsLink)}>{nego_info?.peoples[0]?.Name}</Link>
                  </h3>
                  <a href={"tel:" + nego_info?.peoples[0]?.Phone_Number} className="tel">
                    {nego_info?.peoples[0]?.Phone_Number}
                    {" "}
                  </a>                  
                </div>
              </div>
           }
        </div>
      </div>

      {/* Property image lightbox */}
      {/* {isOpen && (
        <Lightbox
          mainSrc={propertyLightImages[photoIndex]}
          nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
          prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
          }
        />
      )} */}
      {/* {
        !_.isEmpty(props?.DeskImg) &&
        <FsLightbox
          toggler={toggler}
          sources={props?.DeskImg.map(img => img['400x260'])}
          type='image'
        />
      } */}
      {/* Property image lightbox */}
    </React.Fragment>
  );
};


export default PropertyList;
