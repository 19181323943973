import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';

import { CustomLinks } from "../../common/utils";
import { ImageModule } from "../../../modules/Image_Module"

const NegoImg = (props) => {
    return (
        <>
            <ImageModule ImageSrc={props?.nego_info?.peoples[0]?.Image} altText={props?.nego_info?.peoples[0]?.Image.alternativeText} ggfx_results={props?.nego_info?.peoples[0]?.ggfx_results} imagename="peoples.Image.team_avatar" strapi_id={props?.nego_info?.peoples[0]?.id} />
        </>
    )

}
export default NegoImg;
