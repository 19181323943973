import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import FsLightbox from 'fslightbox-react';
import _ from "lodash"
import { ShowProcessedImage } from '../../common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import "./PropertyInfo.scss";
import NoImage from "../../../images/no-image.png"
import Contact01 from "../../../images/searchlist/contact-img01.png";

import { NoResultProperties, NoResultPropertiesAuctions, AuctionIds, GetAuctionProperties } from '../../../queries/common_use_query';
import { People_Email } from "../../../queries/common_use_query"
import PropertyList from "../../SearchListing/PropertyInfo/PropertyList";

import { CustomLinks } from "../../common/utils"


const NoPropertyList = (props) => {
    const [department, setDepartment] = useState('');
    let filterProperties = null;

    useEffect(() => {
        props.department && setDepartment(props.department);
    }, [props.department]);

    const { loading: loading, error: error, data: data } = props.status ? NoResultProperties(props.status, props.department) : NoResultPropertiesAuctions(props.department);
    const { loading: auction_loading, error: auction_error, data: latest_auction_ids } = AuctionIds(props.department);
    const getAuctionList = latest_auction_ids?.auctions?.map(data => data?.crm_id);
    let getAuctionId1 = null;
    let getAuctionId2 = null;
    let auction_residential = "auction_residential";
    let auction_commercial = "auction_commercial";
    if (getAuctionList) {
        getAuctionId1 = getAuctionList[0];
        getAuctionId2 = getAuctionList[1];
    }
    const { loading: auction_property_loading, error: auction_property_error, data: auction_properties } = GetAuctionProperties(getAuctionId1, getAuctionId2, auction_residential, auction_commercial);
    if ((props.department == auction_residential || props.department == auction_commercial) && auction_properties && auction_properties?.properties && auction_properties?.properties.length >= 2) {
        const getDepartment = auction_properties?.properties?.map(data => data.department);
        const auction_type_both = [...new Set(getDepartment)];
        if (auction_type_both?.length == 1) {
            var [auction_department] = auction_type_both;
            if (auction_department) {
                const get_residential_properties = auction_properties && auction_properties?.properties?.filter(property => property?.department === auction_residential);
                const get_commercial_properties = auction_properties && auction_properties?.properties?.filter(property => property?.department === auction_commercial);
                if(props.department == auction_residential){
                    filterProperties = get_residential_properties;
                } else if(props.department == auction_commercial){
                    filterProperties = get_commercial_properties;
                }
            }
        } else if (auction_type_both?.length > 1) {
            const get_residential_properties = auction_properties && auction_properties?.properties?.filter(property => property?.department === auction_residential);
            const get_commercial_properties = auction_properties && auction_properties?.properties?.filter(property => property?.department === auction_commercial);
            if (props.department && props.department2) {
                filterProperties = auction_properties?.properties?.slice(0, 6)
            } else if (props.department == auction_residential) {
                filterProperties = get_residential_properties?.slice(0, 6)
            } else if (props.department == auction_commercial) {
                filterProperties = get_commercial_properties?.slice(0, 6)
            }
        }
        if (filterProperties.length >= 2) {
            filterProperties = { properties: filterProperties };
        } else {
            filterProperties = data
        }
    } else if ((props.department != auction_residential && props.department != auction_commercial) || auction_properties?.properties.length < 2) {
        filterProperties = data
    }
    //const {loading:loading, error:error, data:data} = NoResultProperties(props.status, department);


    //console.log("status", props.status, props.department, data);
    return (
        <React.Fragment>
            {
                filterProperties && filterProperties?.properties?.length > 0 &&
                <>
                    {
                        (props.department === "commercial" || props.department === "residential") ?
                            <p className="results-page-wrapper">We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
                            :
                            <p className="results-page-wrapper">We have selected some of our showcase properties for you to browse below. Alternatively you can register your interest by subscribing to our <Link to={CustomLinks.newsletter_subscription}>auction updates or investments</Link></p>
                    }


                    {
                        filterProperties?.properties?.filter(hit => hit.status !== "Withdrawn").map((hit, index) => {

                            if (index < 6) {

                                // property details url structure
                                let urlStr = ""

                                if (hit.search_type === "sales" && hit.department === 'residential') {
                                    urlStr = `residential-property-for-sale/`
                                } else if (hit.search_type === "lettings" && hit.department === 'residential') {
                                    urlStr = `residential-property-to-rent/`
                                } else if (hit.search_type === "sales" && hit.department === 'commercial') {
                                    urlStr = `commercial-property-for-sale/`
                                } else if (hit.search_type === "lettings" && hit.department === 'commercial') {
                                    urlStr = `commercial-property-to-rent/`
                                } else if (hit.search_type === "sales" && hit.department === 'auction_residential') {
                                    urlStr = `auction-residential-property-for-sale/`
                                } else if (hit.search_type === "sales" && hit.department === 'auction_commercial') {
                                    urlStr = `auction-commercial-property-for-sale/`
                                }
                                // property details url structure

                                var depart_cls = hit.department;
                                if (hit.department === "auction_residential" || hit.department === 'auction_commercial') {
                                    depart_cls = "auction";
                                }

                                return (
                                    <PropertyList
                                        ObjectIDprop={hit.id}
                                        MobImg={hit.images && hit.images.length > 0 ? hit.images[0].url : NoImage}
                                        DeskImg={hit.images && hit.images.length > 0 ? hit.images[0].url : NoImage}
                                        PropertyPrice={hit.price}
                                        Title={hit.title}
                                        Address={hit.display_address}
                                        address_json={hit.address}
                                        Offer={hit.status}
                                        NegoName={hit.crm_negotiator_id}
                                        No={hit.crm_negotiator_id}
                                        Count={hit.images}
                                        ContactImg={Contact01}
                                        searchType={hit.search_type}
                                        propertyDetailsLink={`/${urlStr}${hit.slug}-${hit.id}/`}
                                        OpenMember={props.OpenMember}
                                        Type={"No_Result"}
                                        extra={hit?.extra}
                                        introtext={hit.introtext}
                                        Department={hit.department}
                                        Sub_Area={hit?.subarea}
                                        status={hit.status}
                                        depart_cls={depart_cls}
                                        floorarea_min={hit.floorarea_min}
                                        floorarea_max={hit.floorarea_max}
                                        floorarea_type={hit.floorarea_type}
                                        lotNumber={hit?.extra?.lotNumber}
                                        building={hit.building}
                                        rentPriceMetric={hit?.extra?.rentPriceMetric}
                                        auctionDate={hit?.auctionDate}
                                    />
                                )
                            }
                        })
                    }
                </>
            }
        </React.Fragment>
    )
}

export default NoPropertyList;